<template>
  <section>
    <div
      class="current-info-bar justify-between"
      :class="mobile ? 'mt-20' : 'd-flex mr-4 pl-7'"
    >
      <FiltersSelected />
      <div
        @click="showAddColumns = !showAddColumns"
        @keydown.enter="showAddColumns = !showAddColumns"
      >
        <CheckButton
          :class="mobile ? 'mt-2' : 'mr-6'"
          :text="'favorite.add-new-column'"
        />
      </div>
    </div>
    <div
      class="favorites-list"
      :class="mobile ? 'favorites-list--mobile' : 'favorites-list--margin'"
    >
      <div class="table-information">
        <div class="tables">
          <v-data-table
            v-model="selected"
            disable-pagination
            height="1px"
            :mobile-breakpoint="0"
            :headers="headers"
            :items="combinedFavoritesList"
            item-key="uuid"
            fixed-header
            :class="!mobile ? 'fav-table' : ''"
            hide-default-footer
            :header-props="{ sortIcon: 'mdi-chevron-down' }"
          >
            <template #[`header.isFavorite`]="">
              <SvgIcon
                class="ml-3"
                :icon="'button1.svg'"
                :size="'25'"
              />
            </template>

            <template #[`item.name`]="{ item }">
              <div class="flex justify-between">
                <div
                  class="fav-table--name clickable"
                  @click="exploreCampusDetail(item)"
                  @keydown.enter="exploreCampusDetail(item)"
                >
                  <v-avatar size="80" tile class="image-favorites">
                    <v-img
                      class="image-favorites--border"
                      :src="
                        item.image?.length > 0
                          ? item.image[0].image_link
                          : require('@/assets/icons/school.svg')
                      "
                    />
                  </v-avatar>
                  <div>
                    <h3 v-if="item.name" class="fav-table--name-subtitle">
                      {{ item.name }}
                    </h3>
                    <p class="fav-table--name-commune">
                      {{ getSubtitle(item) }}
                    </p>
                  </div>
                </div>
                <div style="display:flex; margin: auto 0;">
                  <button
                    type="button"
                    class="fav-table--name-button"
                    @click="goToAdmissionProcess(item)"
                    @keydown.enter="goToAdmissionProcess(item)"
                  >
                    {{ $t('favorite.apply') }}
                  </button>
                </div>
              </div>
            </template>
            <template #[`item.isFavorite`]="{ item }">
              <div class="fav-table--isFavorite">
                <FavoriteButton
                  :campus="item"
                  :tag-mix-panel="trackMixPanelFavorites(item)"
                />
              </div>
            </template>
            <template #[`item.PERFORMANCE`]="{ item }">
              <PropertiesGrid
                :key="'PERFORMANCE'"
                :campus="item"
                :property-keys="['PERFORMANCE']"
                :only-icon="true"
                in-favorites
              />
            </template>
            <template #[`item.PAYMENT`]="{ item }">
              <PropertiesGrid
                :key="'PAYMENT'"
                :campus="item"
                :property-keys="['PAYMENT']"
                :only-icon="true"
                in-favorites
              />
            </template>
            <template #[`item.ADMISSION_RISK`]="{ item }">
              <PropertiesGrid
                :key="'ADMISSION_RISK'"
                :campus="item"
                :property-keys="['ADMISSION_RISK']"
                :only-icon="true"
                :abort-previous="false"
                in-favorites
              />
            </template>
            <template #[`item.CAR_TIME`]="{ item }">
              <PropertiesGrid
                :key="'CAR_TIME'"
                :campus="item"
                :property-keys="['CAR_TIME']"
                :only-icon="true"
                in-favorites
              />
            </template>
            <template #[`item.BUS_TIME`]="{ item }">
              <PropertiesGrid
                :key="'BUS_TIME'"
                :campus="item"
                :property-keys="['BUS_TIME']"
                :only-icon="true"
                in-favorites
              />
            </template>
            <template #[`item.DISTANCE`]="{ item }">
              <PropertiesGrid
                :key="'DISTANCE'"
                :campus="item"
                :property-keys="['DISTANCE']"
                :only-icon="true"
                in-favorites
              />
            </template>
            <template #[`item.WALK_TIME`]="{ item }">
              <PropertiesGrid
                :key="'WALK_TIME'"
                :campus="item"
                :property-keys="['WALK_TIME']"
                :only-icon="true"
                in-favorites
              />
            </template>
          </v-data-table>
          <div v-if="showMessage" class="suggest-2">
            {{ $t('favorite.suggest-title-2') }}
          </div>
        </div>
      </div>
      <v-dialog
        v-model="showAddColumns"
        width="450"
        persistent
      >
        <AddColumn
          :headers="headers"
          :list-column-input="listColumnInput"
          @save="save"
          @close="showAddColumns = false"
        />
      </v-dialog>
      <v-dialog
        v-model="campusDetailModal"
        transition="dialog-bottom-transition"
        max-width="800px"
        content-class="favorites__campus-detail-container"
        @click:outside="closeCampusDetail()"
      >
        <CampusDetail
          v-if="campusDetailModal"
          :scroll-to-admissions="scrollToAdmissions"
          style="z-index: 1000"
          @close="closeCampusDetail"
        />
      </v-dialog>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FavoriteButton from '@/components/explorer/favorite/FavoriteButton.vue';
import CampusDetail from '@/components/explorer/general/campus_detail/CampusDetail.vue';
import CONFIG from '@/config';
import CheckButton from '@/components/atoms/buttons/CheckButton.vue';
import trackMixPanel from '@/utils/mixpanel';
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';
import PropertiesGrid from '@/components/organisms/campus/properties/PropertiesGrid.vue';
import { CAMPUS_PAYMENT_TYPES as PAYMENT_TYPES } from '@/constants/category';
import { getLocaleProperty } from '@/utils/locale';
import { getCampusPaymentObject } from '@/utils/categories/payment';
import { LABELS } from '@/constants/explorer/labels';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import { getVacancies } from '@/utils/schools';
import { agreementAbbreviation } from '@/utils/strings';
import AddColumn from './AddColumn.vue';
import FiltersSelected from './FiltersSelected.vue';

export default {
  name: 'FavoritesList',
  components: {
    CampusDetail,
    FavoriteButton,
    CheckButton,
    SvgIcon,
    PropertiesGrid,
    AddColumn,
    FiltersSelected,
  },
  props: {
    favoriteSchools: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      schoolSuggestions: [],
      campusDetailModal: false,
      showAddColumns: false,
      selected: [],
      showMessage: false,
      listColumnInput: [],
      headers: [
        {
          text: this.$t('favorite.table_headers.list'), value: 'isFavorite', align: 'center', sortable: false, id: -1,
        },
        {
          text: this.$t('favorite.table_headers.schools'), align: 'center', value: 'name', id: 0,
        },
      ],
      enabledPropertyKeys: TENANT_CONFIGURATION.SETTINGS.CARD.PROPERTIES,
      noInfo: this.$t('campus-details.school_info'),
      scrollToAdmissions: false,
    };
  },
  computed: {
    ...mapGetters({
      agreementsLabels: 'options/agreementsLabels',
      activeFiltersCount: 'filters/activeFiltersCount',
      labelsByKey: 'explorer/labelsByKey',
    }),
    specialties() {
      return this.labelsByKey(LABELS.SPECIALTIES);
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    diccionario() {
      const diccionario = [
        {
          text: this.$t('favorite.table_headers.list'), value: 'isFavorite', id: -1, sortable: false,
        },
        {
          text: this.$t('favorite.table_headers.schools'), align: 'center', value: 'name', id: 0,
        },
        {
          text: this.$t('favorite.table_headers.vacancies'), align: 'center', value: 'vacancies', id: 1,
        },
        {
          text: this.$t('favorite.table_headers.waitingList'), align: 'center', value: 'waitingList', id: 3,
        },
        {
          text: this.$t('favorite.table_headers.distance'), align: 'center', value: 'DISTANCE', id: 4, sortable: false,
        },
        {
          text: this.$t('favorite.table_headers.carDistance'), align: 'center', value: 'CAR_TIME', id: 5, sortable: false,
        },
        {
          text: this.$t('favorite.table_headers.busDistance'), align: 'center', value: 'BUS_TIME', id: 6, sortable: false,
        },
        {
          text: this.$t('favorite.table_headers.admissions'), align: 'center', value: 'ADMISSION_RISK', id: 7, sortable: false,
        },
        {
          text: this.$t('favorite.table_headers.performance'), align: 'center', value: 'PERFORMANCE', id: 8,
        },
        {
          text: this.$t('favorite.table_headers.price'), align: 'center', value: 'PAYMENT', id: 9, sortable: false,
        },
        {
          text: this.$t('favorite.table_headers.agreement'), align: 'center', value: 'agreement', id: 10,
        },
        {
          text: this.$t('favorite.table_headers.sector'), align: 'center', value: 'sector', id: 11,
        },
        {
          text: this.$t('favorite.table_headers.area'), align: 'center', value: 'area', id: 12,
        },
        {
          text: this.$t('favorite.table_headers.specialty'), align: 'center', value: 'specialty', id: 13,
        },
        {
          text: this.$t('favorite.table_headers.modality'), align: 'center', value: 'modality', id: 14,
        },
        {
          text: this.$t('favorite.table_headers.busDistance'), align: 'center', value: 'WALK_TIME', id: 15, sortable: false,
        },
      ];
      return diccionario;
    },
    favoritesList() {
      // FIXME: Hay un GRAN problema con esto.
      // favoriteSchools es un array de objetos, los cuales pueden tener 3 estructuras distintas!
      // 1. La que viene de agregar un colegio por school card -> Tiene la estructura de la respuesta del poly-filter
      // 2. La que viene de agregar un colegio por sugerencia -> Tiene todo el body que está en elastic search
      // 3. La que estaba guardada y se trajo al iniciar sesión -> Tiene la estructura de la respuesta de campus detail
      // TENEMOS QUE HOMOGENIZAR ESTO!
      return this.parseFavoritesList(this.favoriteSchools);
    },
    combinedFavoritesList() {
      const uniqueSchoolSuggestions = this.schoolSuggestions.filter((suggestion) => !this.favoritesList
        .some((favorite) => favorite.campus_code === suggestion.campus_code));
      return [...this.favoritesList, ...uniqueSchoolSuggestions];
    },
  },
  watch: {
    activeFiltersCount: {
      handler() {
        this.updateSuggestions();
      },
      deep: true,
    },
  },
  async mounted() {
    await this.updateSuggestions();
    this.enabledPropertyKeys = this.enabledPropertyKeys.filter((property) => property !== 'ADMISSION_RISK');
    this.enabledPropertyKeys.forEach((element) => {
      this.headers.push({
        text: this.$t(`dashboard.favorites.properties.${element}`),
        align: 'left',
        value: element,
        id: this.getIdByValue(element),
        sortable: false,
      });
      this.listColumnInput.push(this.getIdByValue(element));
    });
  },
  methods: {
    ...mapActions({
      retrieveCampusDetails: 'institutions/retrieveCampusDetails',
      getCampusRecommendations: 'elasticSearch/getCampusRecommendations',
      getTravelTimes: 'geoTools/getTravelTimes',
      getBusRouteTimes: 'geoTools/getBusRouteTimes',
      resetFilters: 'filters/resetContext',
      setCleanedFilters: 'filters/setCleanedFilters',
    }),
    getIdByValue(value) {
      const found = this.diccionario.find((element) => element.value === value);
      return found.id;
    },
    parseFavoritesList(schools, favorites = true) {
      return schools?.map(
        (campus) => ({
          campus_code: campus.campus_code,
          institution_code: campus.institution_code,
          primary_national_code: campus.primary_national_code,
          image: campus.image_thumb,
          uuid: campus.uuid,
          isFavorite: favorites,
          institution_name: campus.institution_name,
          name: campus.campus_name,
          payment: this.getPaymentLabel(campus, PAYMENT_TYPES.ENROLLMENT_FEE),
          tuition: this.getPaymentLabel(campus, PAYMENT_TYPES.MONTHLY_TUITION),
          sector: this.getSectorLabel(campus),
          commune: this.getLocationLabel(campus),
          // TODO: use getLocaleProperty for these
          grade_min: campus.grades_info?.grade_min_label?.grade_name,
          grade_max: campus.grades_info?.grade_max_label?.grade_name,
          location: campus.location,
          campus_admission_system: campus.campus_admission_system,
          institution_payment_campus: campus.institution_payment_campus,
          programs: campus.programs,
          performance_set: campus.performance_set,
          grades_info: campus.grades_info,
          vacancies: this.hasVacancies(campus),
          waitingList: this.getWaitingListType(campus),
          agreement: this.getAgreementLabel(campus),
          specialty: this.getSpecialtyLabel(campus),
        }),
      );
    },
    getSpecialtyLabel(campus) {
      const specialties = campus?.programs?.map((program) => program?.specialty);

      if (!specialties || specialties.length === 0) {
        return this.noInfo;
      }
      const uniqueSpecialtyIds = new Set(specialties);
      const abbreviationList = Array.from(uniqueSpecialtyIds).map((specialtyId) => this.getSpecialty(specialtyId));
      if (abbreviationList.length > 0 && abbreviationList[0]) {
        return abbreviationList.join(', ');
      }
      return this.noInfo;
    },
    getSpecialty(id) {
      const label = this.specialties.find((specialty) => specialty.id === id);
      return label?.specialty_name;
    },
    getAgreementLabel(campus) {
      const agreements = campus?.agreement_set;
      if (!agreements || agreements.length === 0) {
        return this.noInfo;
      }
      const abbreviationList = agreements.map((agreement) => {
        const agreementLabel = this.getAgreement(agreement.id);
        return agreementAbbreviation(agreementLabel);
      });

      return abbreviationList.join(', ');
    },
    getAgreement(id) {
      const label = this.agreementsLabels.find((agreement) => agreement.id === id);
      return label?.agreement_name;
    },
    getWaitingListType(school) {
      const waitingListType = school?.waiting_list_type;
      if (!waitingListType) {
        return this.noInfo;
      }
      return this.$t(`dashboard.digital_profile.prime.waiting_list.${waitingListType.toLowerCase()}.title`);
    },
    hasVacancies(school) {
      return getVacancies({ school, grades: null }) > 0 ? 'Si' : 'No';
    },
    getPaymentLabel(campus, paymentType) {
      const paymentObject = getCampusPaymentObject({ campus, paymentType });
      if (!paymentObject) {
        return this.noInfo;
      }
      return getLocaleProperty(paymentObject.payment_band, 'description') || this.noInfo;
    },
    getSectorLabel(campus) {
      // FIXME: IMPORTANT - This is because the elastic search service returns `sector`, but the
      //  campus detail service returns `sector_label`. We should unify this.
      const sectorObject = campus.sector ?? campus.sector_label;
      return sectorObject ? getLocaleProperty(sectorObject, 'sector_name') : null;
    },
    getSubtitle(item) {
      const nameByTenant = {
        chile: 'primary_national_code',
        colombia: 'institution_name',
        palmira: 'institution_name',
        dom: 'institution_name',
        newhaven: 'sector',
      };
      return item[nameByTenant[CONFIG.tenant]];
    },
    getLocationLabel(campus) {
      // FIXME: IMPORTANT - This is because the elastic search service returns `commune` field, but the
      //  campus detail service doesn't and we need to get it from the address plocation.
      if (campus.commune) {
        return campus.commune;
      }
      if (campus.campus_location?.length > 0) {
        return campus.campus_location[0]?.plocation?.name;
      }
      return null;
    },
    trackMixPanelFavorites(campus, isRecommendation = false) {
      const nameTagToAddFavorite = isRecommendation
        ? 'favorite_school_from_listFavorites_recommendations'
        : 'favorite_school_from_listFavorites';
      trackMixPanel(
        campus.isFavorite ? 'remove_favorite_school_from_listFavorites' : nameTagToAddFavorite,
        {
          school_id: campus.uuid,
          campus_code: campus.campus_code,
          institution_code: campus.institution_code,
        },
      );
    },
    updateSuggestions() {
      this.getCampusRecommendations()
        .then((suggestions) => {
          this.schoolSuggestions = this.parseFavoritesList(suggestions, false);
        });
    },
    save(listColumnInput) {
      this.listColumnInput = listColumnInput;
      this.headers = [
        {
          text: this.$t('favorite.table_headers.list'), value: 'isFavorite', sortable: false, id: -1,
        },
        {
          text: this.$t('favorite.table_headers.schools'), align: 'center', value: 'name', id: 0,
        },
      ];

      this.listColumnInput.forEach((i) => {
        const found = this.diccionario.find((x) => x.id === i);
        this.headers.push(found);
      });

      this.showAddColumns = false;
    },
    exploreCampusDetail(item) {
      this.retrieveCampusDetails({ searchCode: item.uuid });
      this.campusDetailModal = true;
    },
    goToAdmissionProcess(item) {
      this.scrollToAdmissions = true;
      this.exploreCampusDetail(item);
    },
    closeCampusDetail() {
      this.scrollToAdmissions = false;
      this.campusDetailModal = false;
      this.stopActualVideo();
    },
  },
};
</script>
