<template>
  <div>
    <ApplicationHistory :show-modal="showModal" :app-uuid="appUuid" @toggleModal="toggleModal" />
    <div v-if="!mobile" class="app-list-title" :class="mobile ? 'app-list-title__mobile' : ''">
      <div class="d-flex flex-column">
        <div>
          {{ $t('application.title-list') }} {{ name }}
        </div>
        <div style="font-weight: 400">
          {{ getRoundName }}
        </div>
      </div>
      <!-- FIXME: last-button__mobile class does not exist -->
      <div :class="mobile ? 'last-button__mobile' : ''">
        <v-btn class="last-button" @click="toggleModal()">
          {{ $t('application.last') }} <br />{{ getDate(timestampStudent) }}
        </v-btn>
      </div>
    </div>
    <div v-else class="app-list-title" :class="mobile ? 'app-list-title__mobile' : ''">
      <div :class="mobile ? 'last-button__mobile' : ''">
        <v-btn class="last-button" @click="toggleModal()">
          {{ $t('application.last') }} <br />{{ getDate(timestampStudent) }}
        </v-btn>
      </div>
      {{ $t('application.title-list') }} {{ name }}
    </div>

    <div v-if="loading" class="full-width d-flex justify-center pt-24">
      <div class="LoaderBalls">
        <div v-for="index in 3" :key="index" class="LoaderBalls__item" />
      </div>
    </div>
    <template v-else>
      <div class="app-table" :class="mobile ? 'mobile-children' : 'desktop-children'">
        <v-data-table
          disable-pagination
          :headers="optionHeaders"
          :items="listApplication"
          item-key="id"
          fixed-header
          :mobile-breakpoint="0"
          hide-default-footer
          :header-props="{ sortIcon: null }"
        >
          <template #[`item.index`]="{ index }">
            <div class="index-font">
              {{ index + 1 }}
            </div>
          </template>
          <template #[`item.name`]="{ item }">
            <div class="sede-name">
              <v-avatar v-if="!mobile" size="65" tile class="image-favorites">
                <v-img
                  class="image-favorites--border"
                  :src="
                    item.image.length > 0
                      ? item.image[0].image_link
                      : require('@/assets/icons/school.svg')
                  "
                />
              </v-avatar>
              <div>
                <h3 v-if="item.name" class="sede-name-title">
                  {{ item.name }}
                </h3>
              </div>
            </div>
          </template>
          <template #[`item.arrow`]="{ index }">
            <div v-if="index === 0" class="arrow">
              <img
                :src="require('@/assets/svg/arrow-down.svg')"
                alt="Flecha Abajo"
                @click="changeOrder(index, index + 1)"
                @keydown="keyDeleteSchool()"
              />
            </div>
            <div v-else-if="index === listApplication.length - 1" class="arrow">
              <img
                :src="require('@/assets/svg/arrow-up.svg')"
                alt="Flecha Arriba"
                @click="changeOrder(index, index - 1)"
                @keydown="keyDeleteSchool()"
              />
            </div>
            <div v-else class="arrow">
              <img
                :src="require('@/assets/svg/arrow-up-single.svg')"
                alt="Flecha Unica Arriba"
                @click="changeOrder(index, index - 1)"
                @keydown="keyDeleteSchool()"
              />
              <img
                :src="require('@/assets/svg/arrow-down-single.svg')"
                alt="Flecha Unica Abajo"
                @click="changeOrder(index, index + 1)"
                @keydown="keyDeleteSchool()"
              />
            </div>
          </template>
          <template #[`item.delete`]="{ index }">
            <img
              :src="require('@/assets/svg/delete-app.svg')"
              alt="Icono Eliminar Postulación"
              @click="deleteSchool(index)"
              @keydown="keyDeleteSchool()"
            />
          </template>
        </v-data-table>
      </div>

      <v-btn
        :class="mobile ? 'addmore-button--mobile' : 'addmore-button'"
        @click="toggleModalAdd()"
      >
        {{ $t('application.add') }}
      </v-btn>
      <v-dialog
        v-model="showModalAdd"
        max-height="auto"
        height="auto"
        width="800"
        max-width="800"
        overlay-color="black"
        @click:outside="toggleModalAdd()"
      >
        <v-card class="mx-auto save-card" color="#fbf9f9">
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <div class="info-check add-info-check" :class="mobile ? 'add-title' : ''">
              {{ $t('application.add-info') }}
            </div>
            <v-spacer />
            <v-icon color="#2B5BFF" @click="toggleModalAdd()">
              mdi-close
            </v-icon>
          </v-app-bar>
          <v-card-text class="add-info-text text-left">
            <div class="info-check add-war-check" :class="mobile ? 'add-subtitle' : ''">
              {{ $t('application.add-warning') }}
            </div>
            <div class="app-table check-table add-table">
              <v-data-table
                v-model="selected"
                disable-pagination
                :headers="headersAdd"
                :items="listAdd"
                item-key="id"
                fixed-header
                show-select
                :mobile-breakpoint="0"
                hide-default-footer
                :header-props="{ sortIcon: null }"
              >
                <template #[`item.name`]="{ item }">
                  <div>
                    <h3 v-if="item.name" class="sede-name-title">
                      {{ item.name }}
                    </h3>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
          <v-card-actions class="buttons-card">
            <v-btn
              :class="mobile ? 'cancel-button--mobile' : 'cancel-button'"
              @click="toggleModalAdd()"
            >
              {{ $t('application.cancel') }}
            </v-btn>
            <v-btn :class="mobile ? 'add-button-app--mobile' : 'add-button-app'" @click="addList()">
              {{
                $t('application.add-button')
              }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-btn
        :disabled="disList"
        :class="mobile ? 'postular-button--mobile' : 'postular-button'"
        @click="sendApplication('S', uuid)"
      >
        {{ $t('application.postular-button') }}
      </v-btn>
      <!-- </div> -->
      <v-dialog
        v-if="student"
        v-model="showModalSave"
        max-height="auto"
        height="auto"
        width="360"
        max-width="360"
        overlay-color="black"
        @click:outside="toggleModalSave()"
      >
        <v-card class="mx-auto" color="#fbf9f9">
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <span class="info-check add-info-check">{{ $t('application.sin-estudiante') }}{{ name }}</span>
            <v-spacer />
            <v-icon color="#2B5BFF" @click="toggleModalSave()">
              mdi-close
            </v-icon>
          </v-app-bar>
          <v-card-text class="add-info-text text-left">
            <span class="info-check add-war-check">{{ $t('application.sin-estudiante-info') }}{{ name }}</span>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-else
        v-model="showModalPostular"
        max-height="auto"
        height="auto"
        :width="isRiskWarning ? '500' : '800'"
        max-width="800"
        overlay-color="black"
        @click:outside="toggleModalPostular()"
      >
        <v-card v-if="!showApplicationRisk" class="mx-auto save-card" color="#fbf9f9">
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <span class="info-check add-info-check">{{ $t('application.see-app') }}{{ name }}</span>
            <v-spacer />
          </v-app-bar>
          <v-card-text class="add-info-text resumen">
            <div class="info-check add-war-check">
              {{ $t('application.resumen') }}
            </div>
            <div class="app-table">
              <v-data-table
                disable-pagination
                :headers="headersPostular"
                :items="listApplication"
                item-key="id"
                fixed-header
                :mobile-breakpoint="0"
                hide-default-footer
                :header-props="{ sortIcon: null }"
              >
                <template #[`item.index`]="{ index }">
                  <div class="index-font">
                    {{ index + 1 }}
                  </div>
                </template>
                <template #[`item.name`]="{ item }">
                  <div class="sede-name">
                    <v-avatar size="65" tile class="image-favorites">
                      <v-img
                        class="image-favorites--border"
                        :src="
                          item.image.length > 0
                            ? item.image[0].image_link
                            : require('@/assets/icons/school.svg')
                        "
                      />
                    </v-avatar>
                    <div>
                      <h3 v-if="item.name" class="sede-name-title">
                        {{ item.name }}
                      </h3>
                    </div>
                  </div>
                </template>
                <template #[`item.arrow`]="{ index }">
                  <div v-if="index === 0" class="arrow">
                    <img
                      :src="require('@/assets/svg/arrow-down.svg')"
                      alt="Flecha Abajo"
                      @click="changeOrder(index, index + 1)"
                      @keydown="keyChangeOrder()"
                    />
                  </div>
                  <div v-else-if="index === listApplication.length - 1" class="arrow">
                    <img
                      :src="require('@/assets/svg/arrow-up.svg')"
                      alt="Flecha Arriba"
                      @click="changeOrder(index, index - 1)"
                      @keydown="keyChangeOrder()"
                    />
                  </div>
                  <div v-else class="arrow">
                    <img
                      :src="require('@/assets/svg/arrow-up-single.svg')"
                      alt="Flecha Unica Arriba"
                      @click="changeOrder(index, index - 1)"
                      @keydown="keyChangeOrder()"
                    />
                    <img
                      :src="require('@/assets/svg/arrow-down-single.svg')"
                      alt="Flecha Unica Abajo"
                      @click="changeOrder(index, index + 1)"
                      @keydown="keyChangeOrder()"
                    />
                  </div>
                </template>
                <template #[`item.delete`]="{ index }">
                  <img
                    :src="require('@/assets/svg/delete-app.svg')"
                    alt="Icono Eliminar Postulación"
                    @click="deleteSchool(index)"
                    @keydown="keyDeleteSchool()"
                  />
                </template>
              </v-data-table>
            </div>
          </v-card-text>
          <v-card-actions class="buttons-card">
            <v-btn
              :class="mobile ? 'cancel-button--mobile' : 'cancel-button'"
              @click="toggleModalPostular()"
            >
              {{ $t('application.cancel') }}
            </v-btn>
            <v-btn
              :loading="loadingSendApplication"
              :class="mobile ? 'add-button-app--mobile' : 'add-button-app'"
              @click="sendApplication('C', uuid)"
            >
              {{ $t('application.title') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else class="mx-auto save-card" color="#fbf9f9">
          <v-card-text>
            <h1 class="info-check add-info-check">
              {{ $t('application.title') }}
            </h1>
            <p v-t="'application.risk.text'" class="info-check add-war-check font-normal" />
            <div class="d-flex flex-row justify-center align-center my-5 risk-application">
              <img
                v-if="isRiskWarning"
                src="@/assets/svg/application-warning-risk.svg"
                alt="risk"
              />
              <img v-else src="@/assets/svg/application-warning-risk.svg" alt="risk" />

              <span
                v-t="'application.risk.information'"
                :class="isRiskWarning ? 'risk-application__warning' : 'risk-application__normal'"
              />
            </div>
          </v-card-text>
          <v-card-actions class="d-flex justify-center align-center">
            <v-btn
              v-t="'application.risk.show_more'"
              :class="mobile ? 'add-button-app--mobile' : 'add-button-app'"
              @click="toExplorer(uuid)"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-dialog
      v-model="showSystemModals"
      persistent
      :content-class="mobile ? 'system-dialogs-mobile' : 'system-dialogs'"
      max-width="400"
    >
      <SystemModals
        :no-info-warn-with-location="hasLocation"
        :modal-index="4"
        @closeDialog="showSystemModals = false"
        @editLocation="setLocationModal()"
        @addStudent="setAddStudentModal()"
      />
    </v-dialog>
  </div>
</template>
<script>
import ApplicationHistory from '@/components/explorer/application/ApplicationHistory.vue';
import SystemModals from '@/components/templates/SystemModals.vue';
import CONFIG from '@/config';
import feedbackService from '@/services/feedback.service';
import institutionsService from '@/services/institutions.services';
import '@/styles/components/application/_application.scss';
import utils from '@/utils';
import trackMixPanel from '@/utils/mixpanel';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ApplicationList',
  components: { ApplicationHistory, SystemModals },
  data() {
    return {
      optionHeaders: [],
      selected: [],
      showModal: false,
      showModalAdd: false,
      showModalSave: false,
      showModalPostular: false,
      showApplicationRisk: false,
      isRiskWarning: false,
      loadingSendApplication: false,
      finalApplicationList: [],
      lenList: 0,
      listApplication: [],
      listAdd: [],
      timestampStudent: '',
      disList: false,
      headers: [
        {
          text: '#', align: 'center', sortable: false, value: 'index', id: -1,
        },
        {
          text: 'SEDE EDUCATIVA', align: 'center', sortable: false, value: 'name', id: 0,
        },
        {
          text: 'GRADO', align: 'center', sortable: false, value: 'grade', id: 1,
        },
        {
          text: 'JORNADA', align: 'center', sortable: false, value: 'shift', id: 1,
        },
        {
          text: '', align: 'center', sortable: false, value: 'arrow', id: 1,
        },
        {
          text: '', align: 'center', sortable: false, value: 'delete', id: 1,
        },
      ],
      headersMobile: [
        {
          text: '', align: 'center', sortable: false, value: 'arrow', id: 1,
        },
        {
          text: '#', align: 'center', sortable: false, value: 'index', id: -1,
        },
        {
          text: 'SEDE EDUCATIVA', align: 'center', sortable: false, value: 'name', id: 0,
        },
        {
          text: 'GRADO', align: 'center', sortable: false, value: 'grade', id: 1,
        },
        {
          text: 'JORNADA', align: 'center', sortable: false, value: 'shift', id: 1,
        },
        {
          text: '', align: 'center', sortable: false, value: 'delete', id: 1,
        },
      ],
      headersPostular: [
        {
          text: '#', align: 'center', sortable: false, value: 'index', id: -1,
        },
        {
          text: 'SEDE EDUCATIVA', align: 'center', sortable: false, value: 'name', id: 0,
        },
        {
          text: 'GRADO', align: 'center', sortable: false, value: 'grade', id: 1,
        },
        {
          text: 'JORNADA', align: 'center', sortable: false, value: 'shift', id: 1,
        },
      ],
      headersAdd: [
        {
          text: 'SEDE EDUCATIVA', align: 'center', sortable: false, value: 'name', id: 0,
        },
        {
          text: 'GRADO', align: 'center', sortable: false, value: 'grade', id: 1,
        },
        {
          text: 'JORNADA', align: 'center', sortable: false, value: 'shift', id: 1,
        },
      ],
      appUuid: null,
      showSystemModals: null,
      hasLocation: false,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'favorites/loadingSchoolsDetails',
      favoriteSchoolsDetails: 'favorites/listFavoriteSchools',
      getListApplications: 'favorites/getListApplications',
      currentStudent: 'authentication/currentStudent',
      applications: 'institutions/applicationsByUuid',
      students: 'authentication/students',
      legalGuardianAddress: 'authentication/legalGuardianAddress',
    }),
    getRoundName() {
      if (CONFIG.applicationRound === 0) {
        return this.$t('application.round1');
      }
      if (CONFIG.applicationRound === 1) {
        return this.$t('application.round2');
      }
      return '';
    },
    name() {
      return this.currentStudent.name;
    },
    uuid() {
      return this.currentStudent.uuid;
    },
    mobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        // eslint-disable-next-line
        this.optionHeaders = this.headersMobile;
      } else {
        // eslint-disable-next-line
        this.optionHeaders = this.headers;
      }
      return this.$vuetify.breakpoint.smAndDown;
    },
    student() {
      return JSON.stringify(this.currentStudent) === '{}';
    },
    isValidApplication() {
      if (
        this.addressValidation()
        && this.students
        && this.students.length > 0
        && this.studentValidation()
      ) {
        return true;
      }
      return false;
    },
    getAdmissionSystemLabel() {
      // FIXME: Remove tenant-dependant logic when a base backend is built
      if (CONFIG.tenant === 'palmira') {
        return 1;
      }
      if (CONFIG.tenant === 'colombia') {
        return 3;
      }
      if (CONFIG.tenant === 'chile') {
        return null;
      }
      return null;
    },
  },
  watch: {
    favoriteSchoolsDetails() {
      this.setListApplications();
    },
    applications() {
      this.idStudent = this.currentStudent?.id;
      const isId = (element) => element.id === this.idStudent;
      if (this.applications.findIndex(isId) !== -1) {
        this.timestampStudent = this.applications.find(isId).timestamp;
      } else {
        this.timestampStudent = '';
      }
    },
    currentStudent() {
      this.idStudent = this.currentStudent?.id;
      const isId = (element) => element.id === this.idStudent;
      if (this.applications.findIndex(isId) !== -1) {
        this.timestampStudent = this.applications.find(isId).timestamp;
      } else {
        this.timestampStudent = '';
      }
    },
  },
  async mounted() {
    const getDetails = utils.isGetDetailsFavoritesSchools(this.favoriteSchoolsDetails);
    this.retrieveApplicationsByUuid();
    if (getDetails && this.favoriteSchoolsDetails.length > 0) {
      await this.retrieveListFavorites({ isInLogin: false, getDetails: true });
    } else {
      this.setListApplications();
      this.setLoadingSchoolsDetails(false);
    }
    this.idStudent = this.currentStudent?.id;
    const isId = (element) => element.id === this.idStudent;
    if (this.applications.findIndex(isId) !== -1) {
      this.timestampStudent = this.applications.find(isId).timestamp;
    } else {
      this.timestampStudent = '';
    }
  },
  methods: {
    ...mapActions({
      retrieveListFavorites: 'favorites/retrieveListFavorites',
      retrieveApplicationsByUuid: 'institutions/retrieveApplicationsByUuid',
      setLoadingSchoolsDetails: 'favorites/setLoadingSchoolsDetails',
      success: 'utils/success',
      error: 'utils/error',
    }),
    addressValidation() {
      let studentAddress;
      const lgAddress = this.legalGuardianAddress;
      this.students.forEach((student) => {
        if (student.address) {
          studentAddress = true;
        }
      });
      if (studentAddress || (lgAddress && lgAddress.length)) {
        this.hasLocation = true;
        return true;
      }
      return false;
    },
    studentValidation() {
      let isValid = false;
      // eslint-disable-next-line
      new Promise((resolve) => {
        this.students.forEach((student, index) => {
          if (
            student.first_name
            && student.first_lastname
            && student.gender.id
            && student.identification_number
            && student.birth_date
          ) {
            isValid = true;
          }
          if (index === this.students.length - 1) {
            resolve(isValid);
          }
        });
      });
      return isValid;
    },
    toExplorer(student) {
      this.$router.push({ name: 'Explorer' });
      trackMixPanel('click_show_more_schools_in_application', { selectedStudent: student });
    },
    getDate(timestamp) {
      if (timestamp !== '') {
        // eslint-disable-next-line
        let getDayWithCero = (day) => (day < 10 ? `0${day}` : day);
        const dateStudent = new Date(timestamp);
        const months = [
          'de enero',
          'de febrero',
          'de marzo',
          'de abril',
          'de mayo',
          'de junio',
          'de julio',
          'de agosto',
          'de septiembre',
          'de octubre',
          'de noviembre',
          'de diciembre',
        ];
        return [
          getDayWithCero(dateStudent.getDate()),
          months[dateStudent.getMonth()],
          String(dateStudent.getFullYear()),
        ].join(' ');
      }
      return '';
    },
    setListApplications() {
      this.listApplication = this.getListApplications?.map((i) => ({
        programId: i.programs.id,
        campusCode: i.campus_code,
        id: i.programs.id,
        image: i.image_thumb,
        name: i.campus_name,
        grade: i.programs.grade.grade_name,
        program: i.programs.id,
        shift: i.programs.shift.shift_name,
      }));
      this.lenList = this.listApplication.length;
      if (this.lenList < 1) {
        this.disList = true;
      } else {
        this.disList = false;
      }
      // this.disList = lenList < 1;
    },
    keyChangeOrder() {
      // TODO Accessibility
    },
    changeOrder(item, index) {
      const temp = this.listApplication[item];
      this.$set(this.listApplication, item, this.listApplication[index]);
      this.$set(this.listApplication, index, temp);
    },
    deleteSchool(index) {
      this.$delete(this.listApplication, index);
      this.lenList = this.listApplication.length;
      if (this.lenList < 1) {
        this.disList = true;
      } else {
        this.disList = false;
      }
    },
    keyDeleteSchool() {
      // TODO Accessibility
    },
    async sendApplication(status, student) {
      this.finalApplicationList = this.listApplication.map((item, index) => ({
        id_program: item.program,
        ranking: index + 1,
      }));
      if (this.isValidApplication) {
        this.toggleModalPostular();
        try {
          const round = CONFIG.applicationRound;
          const response = await institutionsService.applicationByStudent({
            uuid: student,
            year: 2022,
            programs: this.finalApplicationList,
            applicationStatus: status,
            admissionSystemLabel: this.getAdmissionSystemLabel,
            round,
          });
          const nameTrackMixpanel = status === 'C' ? 'click_send_application' : 'click_save_application';
          const sendStatusC = response.status === 201 && status === 'C';
          const dataMixPanel = {
            selectedStudent: student,
            programs: this.finalApplicationList,
            applicationStatus: status,
            round,
          };
          if (sendStatusC) {
            this.success(this.$t('application.success-postular'));
            this.loadingSendApplication = true;
            const listPrograms = this.listApplication.map((item) => Number(item.programId));
            const payLoadSimulation = {
              programs: [...listPrograms],
              campuses: [],
              interest_grade:
                this.currentStudent.grades.length > 0 ? Number(this.currentStudent?.grades[0]) : 3,
              applicant_id: Number(this.currentStudent?.id),
              year: 2022,
              origin: 'palmira',
            };
            const unmatched = await this.retrieveRisk(payLoadSimulation);
            if (unmatched > 0.3) {
              this.showApplicationRisk = true;
              this.isRiskWarning = unmatched > 0.7;
              dataMixPanel.risk = unmatched;
            } else {
              this.showModalPostular = false;
            }
            this.loadingSendApplication = false;
            this.retrieveApplicationsByUuid();
          }
          trackMixPanel(nameTrackMixpanel, dataMixPanel);
        } catch (error) {
          this.error(error);
        }
      } else {
        if (
          this.students.length === 0
          && this.legalGuardianAddress
          && this.legalGuardianAddress.length > 0
        ) {
          this.hasLocation = true;
        }
        this.showSystemModals = true;
        return this.showSystemModals;
      }
      return null;
    },
    async retrieveRisk(payload) {
      let unmatched = 0;
      try {
        const { data: dataRisk } = await feedbackService.retrieveSimulationRisk(payload);
        unmatched = dataRisk[0].api_response?.result?.unmatched ?? 0;
      } catch (error) {
        this.error(error);
      }
      return unmatched;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    toggleModalSave() {
      this.showModalSave = !this.showModalSave;
    },
    toggleModalPostular() {
      this.showModalPostular = !this.showModalPostular;
    },
    toggleModalAdd() {
      const difference = this.getListApplications.filter(
        (x) =>
          // eslint-disable-next-line
          !this.listApplication.find((y) => {
            // eslint-disable-next-line
            if (y.name === x.campus_name && y.shift === x.programs.shift.shift_name) return true;
          }),
      );
      this.listAdd = difference.map((i) => ({
        id: i.programs.id,
        image: i.image_thumb,
        name: i.campus_name,
        grade: i.programs.grade.grade_name,
        program: i.programs.id,
        shift: i.programs.shift.shift_name,
      }));
      this.showModalAdd = !this.showModalAdd;
    },
    addList() {
      this.listApplication.push(...this.selected);
      this.showModalAdd = !this.showModalAdd;
      this.lenList = this.listApplication.length;
      if (this.lenList < 1) {
        this.disList = true;
      } else {
        this.disList = false;
      }
      this.selected = [];
    },
  },
};
</script>
<style lang="scss">
.risk-application {
  & > img {
    width: 100%;
    max-width: 140px;
  }
  & > span {
    max-width: 160px;
    border-radius: 6px;
    margin-left: 1rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 19px;
    color: #ffffff;
    padding: 0.5rem 0.7rem;
    text-align: left;
  }
  &__warning {
    background-color: #e3155a;
  }
  &__normal {
    background-color: #ff8311;
  }
}
.LoaderBalls {
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__item {
    $anim-drt: 0.4s;
    $anim-ease: cubic-bezier(0.6, 0.05, 0.15, 0.95);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #2b5bff;
    &:nth-child(1) {
      animation: bouncing $anim-drt alternate infinite $anim-ease;
    }
    &:nth-child(2) {
      animation: bouncing $anim-drt calc($anim-drt/4) alternate infinite $anim-ease backwards;
    }
    &:nth-child(3) {
      animation: bouncing $anim-drt calc($anim-drt/2) alternate infinite $anim-ease backwards;
    }
  }
}
@keyframes bouncing {
  0% {
    transform: translate3d(0, 10px, 0) scale(1.2, 0.85);
  }
  100% {
    transform: translate3d(0, -20px, 0) scale(0.9, 1.1);
  }
}
</style>
