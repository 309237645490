<template>
  <div
    :id="mobile ? '' : 'expansionVar'"
    class="institution-info--wrapper"
    @focus="expandBackground()"
    @mouseover="expandBackground()"
    @mouseleave="compressBackground()"
    @blur="expandBackground()"
  >
    <div
      class="institutions-info__header"
      :class="{
        'institutions-info__header--border': isExpanded === true,
        'institutions-info__header--mobile': mobile,
      }"
      height="31"
      @keydown.enter="clickExpandBackground()"
      @click="clickExpandBackground()"
    >
      <div class="d-flex justify-center align-center">
        <img
          v-if="items.main"
          :src="require(`@/assets/${items.main.src}`)"
          :alt="$t(items.main.name)"
        />
        <strong
          class="institutions-info__header__text institutions-info__header__text--strong ml-1"
        >
          {{ items.main.lengthValue }}
        </strong>
        <p
          v-if="!mobile"
          v-t="items.main.name"
          class="institutions-info__header__text ml-1"
        />
      </div>
    </div>
    <div v-if="isExpanded">
      <div
        v-for="(item, i) in items.others"
        :key="i"
        @click="selectInfoLeft(item)"
        @keydown.enter="selectInfoLeft(item)"
      >
        <div
          class="institution-info-slot d-flex justify-center"
          :class="{ 'institution-info-slot--mobile': mobile }"
        >
          <img
            :src="require(`@/assets/${item.src}`)"
            :alt="$t(item.name)"
          />
          <strong
            class="institutions-info__select__text institutions-info__select__text--strong ml-1"
          >
            {{ item.lengthValue }}
          </strong>
          <div
            v-if="!mobile"
            v-t="item.name"
            style="font-weight: 300"
            class="institutions-info__select__text ml-1"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SelectLeftBar',
  components: {},
  props: {},
  data() {
    return {
      mainItemIndex: 0,
      isExpanded: false,
    };
  },
  computed: {
    ...mapGetters({
      schools: 'institutions/schools',
      exploredCampuses: 'institutions/exploredCampuses',
    }),
    favoritesLength() {
      let favLength = 0;
      this.schools.forEach((school) => {
        if (school.isFavorite) {
          favLength += 1;
        }
      });
      return favLength;
    },
    exploredLength() {
      const exploredLength = this.exploredCampuses.length;
      return exploredLength;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    items() {
      const allItems = [
        {
          lengthValue: this.favoritesLength,
          name: this.$t('map.info.favorites'),
          index: 0,
          src: 'svg/list-institution.svg',
        },
        {
          lengthValue: this.exploredLength,
          name: this.$t('map.info.explored'),
          index: 1,
          src: 'icons/explored.svg',
        },
      ];
      const [mainItem] = allItems.splice(this.mainItemIndex, 1);
      return {
        main: mainItem,
        others: allItems,
      };
    },
  },
  methods: {
    expandBackground() {
      if (!this.mobile) {
        setTimeout(() => {
          this.isExpanded = true;
          return this.isExpanded;
        }, 101);
      }
    },
    compressBackground() {
      setTimeout(() => {
        this.isExpanded = false;
        return this.isExpanded;
      }, 102);
    },
    clickExpandBackground() {
      if (this.mobile) {
        if (!this.isExpanded) {
          this.isExpanded = true;
          return this.isExpanded;
        }
        this.isExpanded = false;
        return this.isExpanded;
      }
      return null;
    },
    selectInfoLeft(item) {
      this.isExpanded = false;
      this.mainItemIndex = item.index;
    },
  },
};
</script>
