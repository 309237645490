<template>
  <div>
    <div v-if="student">
      <v-dialog
        max-height="auto"
        height="auto"
        width="360"
        item-key="name"
        max-width="360"
        overlay-color="black"
        :value="showModal"
        @click:outside="toggleModal()"
      >
        <v-card class="mx-auto" color="#fbf9f9">
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <span class="info-check add-info-check">{{ $t('application.sin-estudiante') }}{{ name }}</span>
            <v-spacer />
            <v-icon color="#2B5BFF" @click="toggleModal()">
              mdi-close
            </v-icon>
          </v-app-bar>
          <v-card-text class="add-info-text text-left">
            <span class="info-check add-war-check">{{ $t('application.sin-estudiante-info') }}{{ name }}</span>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div v-else>
      <v-dialog
        max-height="auto"
        height="auto"
        width="700"
        max-width="700"
        overlay-color="black"
        :value="showModal"
        @click:outside="toggleModal()"
      >
        <v-card class="mx-auto" color="#fbf9f9">
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <div class="d-flex flex-column">
              <span class="info-check add-info-check">{{ $t('application.see-app') }}{{ name }}</span>
              <span class="info-check"> {{ getRoundName }}  </span>
            </div>
            <v-spacer />

            <div v-if="studentOption" class="navbar-favorites__options__students">
              <v-select
                class="elevation-0"
                hide-details
                :items="studentList"
                :value="currentStudent"
                item-text="name"
                item-value="uuid"
                solo
                dense
                height="36"
                color="#2b5bff"
                :menu-props="{ bottom: true, offsetY: true }"
                @change="changeCurrentStudent"
              >
                <template #selection="{ item }">
                  <span class="navbar-favorites__options__students--name">
                    {{ item.name.split(" ", 1)[0] }}
                  </span>
                </template>
              </v-select>
            </div>
            <v-spacer />
            <v-icon color="#2B5BFF" @click="toggleModal()">
              mdi-close
            </v-icon>
          </v-app-bar>
          <v-card-text class="add-info-text resumen">
            <div class="info-check add-war-check">
              {{ $t('application.date-last') }} {{ getDate(timestampStudent) }}
            </div>
            <div class="app-table">
              <v-data-table
                disable-pagination
                :headers="headers"
                :items="listApplication"
                fixed-header
                :mobile-breakpoint="0"
                hide-default-footer
                :header-props="{ sortIcon: null }"
              >
                <template #[`item.index`]="{ item }">
                  <div class="index-font">
                    {{ item.index }}
                  </div>
                </template>
                <template #[`item.name`]="{ item }">
                  <div>
                    <h3 v-if="item.name" class="sede-name-title">
                      {{ item.name }}
                    </h3>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
          <v-card-actions v-if="showDelete && deleteApp" class="delete-button">
            <v-btn tile color="#4002F0" class="delete-button__text" @click="sendApplication('C')">
              <v-icon left>
                mdi-delete
              </v-icon>
              {{ $t('application.delete-postular') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import '@/styles/components/application/_application.scss';
import { mapActions, mapGetters } from 'vuex';
import institutionsService from '@/services/institutions.services';
import CONFIG from '@/config';

export default {
  name: 'ApplicationHistory',
  props: {
    showModal: {
      required: true,
      type: Boolean,
    },
    deleteApp: {
      required: false,
      type: Boolean,
      default: true,
    },
    studentOption: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      listApplication: [],
      institutionsService,
      idStudent: '',
      timestampStudent: '',
      headers: [
        {
          text: '#', align: 'center', sortable: false, value: 'index', id: -1,
        },
        {
          text: 'SEDE EDUCATIVA', align: 'center', sortable: false, value: 'name', id: 0,
        },
        {
          text: 'GRADO', align: 'center', sortable: false, value: 'grade', id: 1,
        },
        {
          text: 'JORNADA', align: 'center', sortable: false, value: 'shift', id: 1,
        },
      ],
      showDelete: false,
    };
  },
  computed: {
    ...mapGetters({
      applications: 'institutions/applicationsByUuid',
      currentStudent: 'authentication/currentStudent',
      studentList: 'authentication/students',
    }),
    getRoundName() {
      if (CONFIG.applicationRound === 0) {
        return this.$t('application.round1');
      }
      if (CONFIG.applicationRound === 1) {
        return this.$t('application.round2');
      }
      return '';
    },
    student() {
      return JSON.stringify(this.currentStudent) === '{}';
    },
    name() {
      return this.currentStudent.name;
    },
  },
  watch: {
    currentStudent() {
      this.idStudent = this.currentStudent?.id;
      const isId = (element) => element.id === this.idStudent;
      if (this.applications.findIndex(isId) !== -1) {
        this.timestampStudent = this.applications.find(isId).timestamp;
        this.listApplication = this.applications.find(isId).ranking.map((i) => ({
          index: i.application_program_ranking,
          name: i.program.campus_code,
          grade: i.program.gradetrack.grade_label.grade_name,
          shift: i.program.shift_label.shift_name,
        }));
      } else {
        this.listApplication = [];
        this.timestampStudent = '';
      }
      this.showDelete = this.listApplication.length > 0;
    },
    applications() {
      this.idStudent = this.currentStudent?.id;
      const isId = (element) => element.id === this.idStudent;
      if (this.applications.findIndex(isId) !== -1) {
        this.timestampStudent = this.applications.find(isId).timestamp;
        this.listApplication = this.applications.find(isId).ranking.map((i) => ({
          index: i.application_program_ranking,
          name: i.program.campus_code,
          grade: i.program.gradetrack.grade_label.grade_name,
          shift: i.program.shift_label.shift_name,
        }));
      } else {
        this.listApplication = [];
        this.timestampStudent = '';
      }
      this.showDelete = this.listApplication.length > 0;
    },
  },
  mounted() {
    this.retrieveApplicationsByUuid();
    this.idStudent = this.currentStudent?.id;
    const isId = (element) => element.id === this.idStudent;
    if (this.applications.findIndex(isId) !== -1) {
      this.timestampStudent = this.applications.find(isId).timestamp;
      this.listApplication = this.applications.find(isId).ranking.map((i) => ({
        index: i.application_program_ranking,
        name: i.program.campus_code,
        grade: i.program.gradetrack.grade_label.grade_name,
        shift: i.program.shift_label.shift_name,
      }));
    } else {
      this.listApplication = [];
      this.timestampStudent = '';
    }
    this.showDelete = this.listApplication.length > 0 && this.deleteApp;
  },
  methods: {
    ...mapActions({
      retrieveApplicationsByUuid: 'institutions/retrieveApplicationsByUuid',
      success: 'utils/success',
      error: 'utils/error',
      setCurrentStudent: 'authentication/setCurrentStudent',
      retrieveCampuses: 'institutions/retrieveCampuses',
      retrieveExploredCampuses: 'institutions/retrieveExploredCampuses',
      retrieveExploredSchoolCard: 'institutions/retrieveExploredSchoolCard',
      retrieveFavorites: 'favorites/retrieveListFavorites',
      raiseError: 'utils/error',
    }),
    toggleModal() {
      this.$emit('toggleModal');
    },
    getDate(timestamp) {
      if (timestamp !== '') {
        // eslint-disable-next-line
        const d = new Date(timestamp);
        let month = '';
        switch (d.getMonth() + 1) {
          case 1:
            month = 'de enero';
            break;
          case 2:
            month = 'de febrero';
            break;
          case 3:
            month = 'de marzo';
            break;
          case 4:
            month = 'de abril';
            break;
          case 5:
            month = 'de mayo';
            break;
          case 6:
            month = 'de junio';
            break;
          case 7:
            month = 'de julio';
            break;
          case 8:
            month = 'de agosto';
            break;
          case 9:
            month = 'de septiembre';
            break;
          case 10:
            month = 'de octubre';
            break;
          case 11:
            month = 'de noviembre';
            break;
          case 12:
            month = 'de diciembre';
            break;
          default:
            month = '';
            break;
        }
        return [d.getDate(), month, String(d.getFullYear())].join(' ');
      }
      return '';
    },
    async sendApplication(status) {
      try {
        const response = await this.institutionsService.applicationByStudent({
          uuid: this.currentStudent.uuid,
          year: 2022,
          programs: [],
          application_status: status,
        });
        if (response.status === 201) {
          this.success(this.$t('application.delete-postulacion'));
        }
        this.retrieveApplicationsByUuid();
      } catch (error) {
        this.error(error);
      }
    },
    changeCurrentStudent(studentUUID) {
      const student = this.studentList.find((st) => st.uuid === studentUUID);
      // this.$emit('uuid', student.uuid);
      this.setCurrentStudent({ student, hotjar: this.$hj })
        // .then(() => this.retrieveFavorites())
        .then(() => this.retrieveExploredCampuses())
        .then(() => this.retrieveExploredSchoolCard())
        .then(() => this.retrieveCampuses({ callback: null }))
        .catch((error) => this.raiseError(error));
    },
  },
};
</script>
