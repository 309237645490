<template>
  <div class="explorer">
    <div v-if="mounted" class="explorer__map-container">
      <MarkerMap />
    </div>
    <div v-else class="explorer__map-container">
      <MapPlaceholder />
    </div>
    <div v-if="hasPriority && showCard" class="explorer__legend-container white--text">
      <MapLegend />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MapPlaceholder from '@/components/explorer/guest/MapPlaceholder.vue';
import MapLegend from '@/components/explorer/map/MapLegend.vue';
import MarkerMap from '@/components/explorer/map/MarkerMap.vue';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import { LABELS } from '@/constants/explorer/labels';
import CONFIG from '@/config';
import { isEmbedded } from '@/utils/embedded';

export default {
  name: 'InstitutionsExplorer',
  components: {
    MarkerMap,
    MapLegend,
    MapPlaceholder,
  },
  metaInfo() {
    return {
      title: `${this.$tc('dashboard.breadcrumbs.app_name')} | ${this.$tc('dashboard.breadcrumbs.map')}`,
      meta: [],
    };
  },
  props: {
    logout: {
      type: Boolean,
      default: false,
    },
    rerender: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mounted: false,
      showGreetingsModal: false,
      showOrHideMapModal: false,
      // rerenderAux: false,
    };
  },
  computed: {
    ...mapGetters({
      isGuest: 'authentication/isGuest',
      campusDetails: 'institutions/campusDetails',
      // TODO: Check this logic, im not sure if this is being used
      showCard: 'explorer/showCard',
      legalGuardian: 'authentication/legalGuardian',
      activeGrades: 'authentication/activeGrades',
      activeModal: 'utils/activeModal',
    }),
    hasPriority() {
      // FIXME: Ver que es esto. Se cambió el getter a campusDetails porque el otro nunca se estaba seteando.
      // Es decir, creo que esto nunca estuvo funcionando, por lo que nunca se mostró MapLegend.
      // Dicho esto, no creo que el campusDetails tenga una propiedad student_priorities, por lo que esto
      // sigue sin funcionar. En caso de que se quiera mantener, limpiar este código.
      const priorities = this.campusDetails?.student_priorities;
      if (priorities) {
        const values = Object.keys(priorities).map((key) => priorities[key]);
        return values.includes(true);
      }
      return false;
    },
  },
  watch: {
    logout() {
      if (this.logout === true) {
        this.$emit('logoutSuccess');
        this.showOrHideMapModal = true;
        this.showGreetingsModal = true;
      }
    },
    rerender: {
      handler(newVal) {
        if (newVal > 0) {
          this.render();
        }
      },
    },
    activeGrades: {
      handler(newVal) {
        const { source, grades } = newVal;
        const { landingPluginEnabled } = CONFIG;
        const hasSelectedGrades = source && source !== 'default' && grades && grades.length > 0;
        const landingDisabledByRoute = this.$route.meta?.disableLanding;
        const embedded = isEmbedded();
        if (
          landingPluginEnabled // Functionality is enabled
          && this.isGuest // User is a guest
          && !hasSelectedGrades // User has not selected grades
          && !landingDisabledByRoute // Landing is not disabled by route
          && !embedded // Not embedded in an iframe
        ) {
          this.activateModal({ name: 'LandingPlugin' });
        } else if (this.activeModal === 'LandingPlugin') {
          this.deactivateModal();
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    this.checkLabels({ keysLabels: [LABELS.SPECIALTIES] });
    this.render();
    await this.openDigitalProfileFromUrl();
  },
  methods: {
    ...mapActions({
      setMapModal: 'authentication/setMapModal',
      setGuestData: 'authentication/setGuestData',
      cleanGuestData: 'authentication/cleanGuestData',
      updateCurrentSchool: 'institutions/updateCurrentSchool',
      sendGeneralExploredCampus: 'institutions/sendGeneralExploredCampus',
      setExitsInfoGuest: 'authentication/setExitsInfoGuest',
      checkLabels: 'explorer/checkLabels',
      activateModal: 'utils/activateModal',
      deactivateModal: 'utils/deactivateModal',
    }),
    async openDigitalProfileFromUrl() {
      const campusCode = this.$route.params.code ?? 'no url';
      if (campusCode !== 'no url') {
        await this.setGuest();
        await this.updateCurrentSchool({ campusCode, fromUrl: true });
        this.sendGeneralExploredCampus({ campusCode, event: 'open_digital_profile_from_url' });
      }
    },
    async setGuest() {
      const { LOCATION, GRADES } = TENANT_CONFIGURATION.DEFAULTS;
      await this.setGuestData({
        location: LOCATION,
        grades: GRADES,
      }).then(() => {
        this.setExitsInfoGuest({ exitsInfoGuest: false });
      });
    },
    render() {
      if (!this.isGuest) {
        this.cleanGuestData();
        this.showOrHideMapModal = false;
        this.mounted = true;
        this.setMapModal({ show: true });
      } else if (this.legalGuardian.guestToken !== '') {
        this.showOrHideMapModal = false;
        this.mounted = true;
        this.setMapModal({ show: true });
      } else {
        this.showOrHideMapModal = true;
        this.showGreetingsModal = true;
        this.mounted = true;
        this.setMapModal({ show: true });
      }
    },
  },
};
</script>
