<template>
  <div
    class="selector-buttons"
    :class="{ 'selector-buttons--mobile': small }"
  >
    <button
      type="button"
      class="d-flex align-center"
      :class="{
        'selector-buttons--completed': completed && !selected && !verticalButtons && !small,
        'selector-buttons--selected': selected && !verticalButtons && !small,
        'selector-buttons--pending': !completed && !selected && !verticalButtons && !small,
        'selector-buttons--vertical--button': verticalButtons,
        'selector-buttons--vertical--selected': verticalButtons && (completed || selected),
        'selector-buttons--vertical--pending': verticalButtons && (!completed && !selected),
        'selector-buttons--mobile--button': small,
        'selector-buttons--mobile--selected': small && (completed || selected),
        'selector-buttons--mobile--pending': small && (!completed && !selected),
      }"
      @click="$emit('setValue', text)"
    >
      {{ $t(text) }}
      <section style="margin-left: 16px">
        <img
          v-if="completed || selected"
          src="@/assets/svg/add-white.svg"
          alt="Close"
          style="width: 24px"
        >
        <img
          v-if="!completed && !selected"
          src="@/assets/svg/add-primary-dark.svg"
          alt="Add"
          style="width: 24px"
        >
      </section>
    </button>
  </div>
</template>

<script>
export default {
  name: 'SideSelectorButtons',
  components: {},
  props: {
    completed: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    verticalButtons: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
