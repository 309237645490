<template>
  <div class="edit-info-mobile-bar">
    <button
      v-if="Object.keys(currentStudent).length === 0 && studentList.length === 0"
      v-t="isGuest ? 'header.guest' : 'desktop.header.add-student'"
      type="button"
      height="21"
      class="edit-info-mobile-bar--login-button"
      @click="isGuest ? goToLogin() : addStudent()"
    />
    <v-select
      v-if="!isGuest && studentList.length > 0"
      v-show="!isGuest"
      id="student-select"
      class="edit-info-mobile-bar--users"
      hide-details
      :items="allMainFilters"
      :value="getMainFilter()"
      item-text="name"
      item-value="uuid"
      solo
      dense
      item-color="#1E0C61"
      :menu-props="{ bottom: true, offsetY: true }"
      append-icon="$expand"
      @change="changeCurrentStudent"
      @click="expandSelect()"
    >
      <template #selection="{ item }">
        <span
          class="edit-info-mobile-bar--users--text"
        >
          {{ setItemName(item) }}
        </span>
      </template>
      <template slot="item" slot-scope="{ item }">
        <img
          v-if="!item.lg"
          style="margin-right: 8px"
          width="20"
          :src="require('@/assets/icons/face-primary.svg')"
          alt="Icono tipo usuario"
        />
        <span style="color: #1E0C61; font-size: 14px">
          {{ setItemName(item) }}
        </span>
      </template>
    </v-select>
    <button
      v-if="location() && location() !== 'no-info'"
      type="button"
      class="edit-info-mobile-bar--location-button"
      @click="showDialogs('location')"
    >
      <SvgIcon :icon="getLocationIcon" :size="'24'" />
      <div>
        {{
          !isGuestClickFirstTime.location && isGuest ? $t('desktop.header.location') : location()
        }}
      </div>
    </button>
    <button
      v-if="getLevelName"
      type="button"
      class="edit-info-mobile-bar--location-button"
      @click="showDialogs('level')"
    >
      {{ getLevelName }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';
import trackMixPanel from '@/utils/mixpanel';
import { getLocaleProperty } from '@/utils/locale';

export default {
  name: 'EditInfoMobileBar',
  components: {
    SvgIcon,
  },
  data() {
    return {
      showSelection: null,
    };
  },
  computed: {
    ...mapGetters({
      isGuestClickFirstTime: 'authentication/isGuestClickFirstTime',
      studentList: 'authentication/students',
      currentStudent: 'authentication/currentStudent',
      isGuest: 'authentication/isGuest',
      legalGuardianUUID: 'authentication/legalGuardianUUID',
      legalGuardianAddress: 'authentication/legalGuardianAddress',
      allMainFilters: 'authentication/allMainFilters',
      guestLocation: 'authentication/guestLocation',
      gradeOptions: 'options/grades',
      activeGrades: 'authentication/activeGrades',
    }),
    getLevelName() {
      const { grades, source } = this.activeGrades;
      if (source === 'default' || grades.length === 0) {
        return this.$t('desktop.header.grade');
      }
      const gradeLabel = this.gradeOptions.find((grade) => grade.id === grades[0]);
      const gradeName = getLocaleProperty(gradeLabel, 'grade_name');
      return grades.length > 1
        ? `${gradeName} (+${grades.length - 1})`
        : gradeName;
      // If there is no grade to display, we return the default label
    },
    getLocationIcon() {
      if (this.isGuest) {
        return this.selectFilter === 0 ? 'home-white.svg' : 'home-primary.svg';
      }
      if (
        this.currentStudent && Object.keys(this.currentStudent).length > 0
        && this.currentStudent.address
        && this.currentStudent.address[0].address_name
      ) {
        if (this.currentStudent.address[0].address_name.id === 1) {
          return this.selectFilter === 0 ? 'home-white.svg' : 'home-primary.svg';
        }
        if (this.currentStudent.address[0].address_name.id === 2) {
          return this.selectFilter === 0 ? 'job-white.svg' : 'job-primary.svg';
        }
        if (
          this.currentStudent.address[0].address_name.id !== 2
          && this.currentStudent.address[0].address_name.id !== 1
        ) {
          return this.selectFilter === 0 ? 'pin-white.svg' : 'pin-primary.svg';
        }
      }
      if (
        this.legalGuardianAddress && this.legalGuardianAddress.length > 0
        && this.legalGuardianAddress[0].address_name
      ) {
        if (this.legalGuardianAddress[0].address_name.id === 1) {
          return this.selectFilter === 0 ? 'home-white.svg' : 'home-primary.svg';
        }
        if (this.legalGuardianAddress[0].address_name.id === 2) {
          return this.selectFilter === 0 ? 'job-white.svg' : 'job-primary.svg';
        }
        if (
          this.legalGuardianAddress[0].address_name.id !== 2
          && this.legalGuardianAddress[0].address_name.id !== 1
        ) {
          return this.selectFilter === 0 ? 'pin-white.svg' : 'pin-primary.svg';
        }
      }
      return this.selectFilter === 0 ? 'pin-white.svg' : 'pin-primary.svg';
    },
  },
  watch: {
    currentStudent: {
      handler() {
        this.showSelection = false;
      },
    },
  },
  methods: {
    ...mapActions({
      setForcedStep: 'userRegister/setForcedStep',
      setCurrentStudent: 'authentication/setCurrentStudent',
      retrieveCampuses: 'institutions/retrieveCampuses',
      retrieveFavorites: 'favorites/retrieveListFavorites',
      retrieveExploredCampuses: 'institutions/retrieveExploredCampuses',
      retrieveExploredSchoolCard: 'institutions/retrieveExploredSchoolCard',
      error: 'utils/error',
      navigateTo: 'explorer/navigateTo',
    }),
    showDialogs(option) {
      if (option === 'location') {
        this.$emit('showEditLocation');
        trackMixPanel('map_location_click');
      }
      if (option === 'level') {
        trackMixPanel('map_grade_click');
        this.$emit('showEditGrade');
      }
    },
    getMainFilter() {
      if (Object.keys(this.currentStudent).length > 0) {
        return this.allMainFilters.find((filter) => filter.uuid === this.currentStudent.uuid);
      }
      return this.allMainFilters.find((filter) => filter.lg === true);
    },
    changeCurrentStudent(uuid) {
      if (uuid === this.legalGuardianUUID) {
        this.setCurrentStudent({ student: {} })
          .then(() => this.retrieveCampuses({ callback: null }))
          .then(() => {
            this.showSelection = false;
          });
      } else {
        const student = this.studentList.find((st) => st.uuid === uuid);
        if (student.address && student.address[0].address_lat && student.address[0].address_lon) {
          this.navigateTo({
            lat: student.address[0].address_lat,
            lng: student.address[0].address_lon,
          });
        }
        this.setCurrentStudent({ student })
          .then(() => {
            this.showSelection = false;
            this.retrieveFavorites();
          })
          .then(() => this.retrieveExploredCampuses())
          .then(() => this.retrieveExploredSchoolCard())
          .catch((error) => this.error(error));
      }
      this.showSelection = false;
    },
    expandSelect() {
      if (this.showSelection) {
        this.showSelection = false;
        return this.showSelection;
      }
      this.showSelection = true;
      return this.showSelection;
    },
    setItemName(item) {
      if (item) {
        if (item.name) {
          return item.name.split(' ', 1)[0];
        }
        if (item.first_name) {
          return item.first_name.split(' ', 1)[0];
        }
        if (item.firstName) {
          return item.firstName.split(' ', 1)[0];
        }
      }
      return '';
    },
    goToLogin() {
      this.setForcedStep({ step: 0 }).then(() => {
        this.$router.push('/login');
      });
    },
    addStudent() {
    },
    location() {
      if (
        this.isGuest
      ) {
        if (this.guestLocation) {
          if (Object.keys(this.guestLocation).length > 0) {
            return this.guestLocation.municipalityName;
          }
        }
        return this.$t('desktop.header.location');
      }
      if (
        this.currentStudent
        && Object.keys(this.currentStudent).length > 0
        && this.currentStudent.address
        && this.currentStudent.address.length > 0
      ) {
        if (Object.keys(this.currentStudent.address[0].address_name).length > 0) {
          return this.getLocationName(this.currentStudent.address[0]?.address_name?.id);
        }
        return this.$t('desktop.header.location_type.my_location');
      }
      if (
        this.legalGuardianAddress
        && this.legalGuardianAddress.length > 0
         && Object.keys(this.currentStudent).length === 0
      ) {
        if (this.legalGuardianAddress[0]?.address_name) {
          return this.getLocationName(this.legalGuardianAddress[0].address_name.id);
        }
        if (this.legalGuardianAddress[0].address_details === ' ' && !this.legalGuardianAddress[0].location) {
          return this.$t('desktop.header.location');
        }
        return this.$t('desktop.header.location_type.my_location');
      }
      return this.$t('desktop.header.location');
    },
    getLocationName(locationTypeId) {
      if (locationTypeId === 1) {
        return this.$t('desktop.header.location_type.home');
      }
      if (locationTypeId === 2) {
        return this.$t('desktop.header.location_type.work');
      }
      if (locationTypeId !== 2 && locationTypeId !== 1
      ) {
        return this.$t('desktop.header.location_type.my_location');
      }
      return this.$t('desktop.header.location_type.my_location');
    },
  },
};
</script>
