<template>
  <section class="app-view">
    <NavbarApplication class="nav-app" />
    <div :class="mobile ? '' : 'wrap-application'">
      <div :class="mobile ? 'app-info--mobile' : 'app-info'">
        <div v-if="mobile" class="read-button">
          <div class="app-info__title">
            {{ $t('application.title-palmira') }}
          </div>
          <div class="app-info__subtitle">
            {{ $t('application.description') }}
          </div>
          <div v-show="readMore" class="app-info__subtitle">
            {{ $t('application.description-2') }}
          </div>
          <div v-show="readMore" class="app-info__subtitle">
            {{ $t('application.description-3') }}
          </div>
          <v-btn tile @click="readMore = !readMore">
            <v-icon v-if="!readMore" left>
              mdi-plus-circle
            </v-icon>
            <v-icon v-else left>
              mdi-minus-circle
            </v-icon>
            <span v-if="readMore">Ver menos</span>
            <span v-else>Ver más</span>
          </v-btn>
        </div>
        <div v-else>
          <div class="app-info__title">
            {{ $t('application.title-palmira') }}
          </div>
          <div class="app-info__subtitle">
            {{ $t('application.description') }}
          </div>
          <div class="app-info__subtitle">
            {{ $t('application.description-2') }}
          </div>
          <div class="app-info__subtitle">
            {{ $t('application.description-3') }}
          </div>
        </div>
      </div>
      <ApplicationList class="app-list" />
    </div>
  </section>
</template>

<script>
import '@/styles/components/application/_application.scss';
// import { mapActions } from 'vuex';
import ApplicationList from '@/components/explorer/application/ApplicationList.vue';
import NavbarApplication from '@/components/explorer/application/NavbarApplication.vue';

// ,src\styles\components\application\_application.scss
export default {
  name: 'ApplicationLayout',
  // mounted() {
  //   this.retrieveListFavorites();
  // },
  components: {
    NavbarApplication,
    ApplicationList,
  },
  metaInfo() {
    return {
      title: `${this.$tc('dashboard.breadcrumbs.app_name')} | ${this.$tc('dashboard.breadcrumbs.application')}`,
      meta: [],
    };
  },
  data() {
    return {
      readMore: false,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  // methods: {
  //   ...mapActions({
  //     retrieveListFavorites: 'favorites/retrieveListFavorites',
  //   }),
  // },
};
</script>
