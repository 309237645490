<template>
  <DashboardTemplate
    :sections="filteredSections"
    :grid-area-component="gridAreaComponentAdmissions"
    @setCurrentCategory="setCurrentCategory"
  >
    <template #navbarItems>
      <div class="dashboard-navbar__route">
        {{ $t("dashboard.admissions.title") }}
      </div>
      <div class="dashboard-navbar__separator">
        /
      </div>
      <div class="dashboard-navbar__route">
        {{ categoryTitle }}
      </div>
    </template>
  </DashboardTemplate>
</template>

<script>
import DashboardTemplate from '@/components/dashboard/Template.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'AdmissionsView',
  components: {
    DashboardTemplate,
  },
  metaInfo() {
    return {
      title: `${this.$tc('dashboard.breadcrumbs.app_name')} | ${this.$tc('dashboard.breadcrumbs.admission')}`,
      meta: [
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
  data() {
    return {
      currentCategory: 'admission-process',
      sections: [
        {
          name: 'admission-category-selector',
          title: this.$t('dashboard.admissions.complete_profile_title'),
          permissions: ['headmaster'],
          mobileHeight: '90px',
          show: true,
          background: 'white',
          gridArea: 'admission-category-selector',
        },
        {
          name: 'admission-process',
          title: this.$t('dashboard.admissions.admission_process_title'),
          permissions: ['headmaster'],
          category: 'admission-process',
          mobileHeight: 'calc(100vh - 170px)',
          gridArea: 'admission-process',
        },
        {
          name: 'admission-frequently-asked-questions',
          title: this.$t('dashboard.admissions.frequently_questions_title'),
          permissions: ['headmaster'],
          category: 'admission-frequently-asked-questions',
          mobileHeight: 'calc(100vh - 170px)',
          gridArea: 'admission-frequently-asked-questions',
        },
        {
          name: 'admission-vacancies',
          title: this.$t('dashboard.admissions.vacancies_title'),
          permissions: ['headmaster'],
          category: 'admission-vacancies',
          mobileHeight: 'calc(100vh - 170px)',
          background: 'transparent',
          gridArea: 'admission-vacancies',
        },
      ],
      gridAreaComponentAdmissions: '"admission-category-selector admission-category-selector admission-process admission-process admission-process admission-process" "admission-category-selector admission-category-selector admission-process admission-process admission-process admission-process" "admission-category-selector admission-category-selector admission-process admission-process admission-process admission-process" "admission-category-selector admission-category-selector admission-process admission-process admission-process admission-process"',
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
    categoryTitle() {
      return this.sections.filter(
        (section) => section.name === this.currentCategory,
      )[0].title;
    },
    filteredSections() {
      /* only shows admission-category-selector + the current category selected */
      return this.sections.filter(
        (section) => section.name === 'admission-category-selector'
          || section.category === this.currentCategory,
      );
    },
  },
  watch: {
    'campusDetail.campus_name': {
      handler() {
        this.currentCategory = 'admission-process';
      },
      immediate: true,
    },
  },
  methods: {
    // sets category when clicking on admission-category-selector section
    setCurrentCategory(category) {
      this.currentCategory = category;
      this.gridAreaComponentAdmissions = `"admission-category-selector admission-category-selector ${category} ${category} ${category} ${category}" "admission-category-selector admission-category-selector ${category} ${category} ${category} ${category}" "admission-category-selector admission-category-selector ${category} ${category} ${category} ${category}" "admission-category-selector admission-category-selector ${category} ${category} ${category} ${category}"`;
    },
  },
};
</script>
