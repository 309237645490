<template>
  <ApplicationStudentSelectionModal @closeModal="$emit('closeModal')" />
</template>

<script>
import ApplicationStudentSelectionModal from '@/components/organisms/modals/ApplicationStudentSelectionModal.vue';

export default {
  name: 'MultiSelectionModals',
  components: {
    ApplicationStudentSelectionModal,
  },
};
</script>
