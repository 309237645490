var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-inline-flex justify-center align-center marker",on:{"click":_vm.explore,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.explore.apply(null, arguments)}}},[(!_vm.flags.hideCategories)?_c('div',{staticClass:"marker-categories",style:({
      width: `${1.2 * _vm.iconSize}px`,
      height: `${1.2 * _vm.iconSize}px`,
      margin: `-${0.1 * _vm.iconSize}px -${0.1 * _vm.iconSize}px`,
    })},[_c('div',{staticClass:"marker-categories--row"},[(_vm.hasDroneFlight && _vm.currentZoom >= _vm.audiovisualsZoomThreshold)?_c('SVGIcon',{attrs:{"icon":"campus-properties/multimedia/drone.svg","size":"25","alt-text":"Has drone flight"}}):_vm._e(),(_vm.has360Tour && _vm.currentZoom >= _vm.audiovisualsZoomThreshold)?_c('SVGIcon',{attrs:{"icon":"campus-properties/multimedia/tour360.svg","size":"25","alt-text":"Has drone flight"}}):_vm._e()],1),_c('div',{staticClass:"marker-categories--row"},[(_vm.currentZoom >= _vm.categoriesZoomThreshold)?_c('PropertyCategoryTile',{key:`performance_${_vm.school.campus_code}_${Math.random()}}`,attrs:{"campus":_vm.school,"property-key":_vm.CAMPUS_PROPERTIES.PERFORMANCE,"only-icon":"","hide-unknown":""}}):_vm._e(),(_vm.currentZoom >= _vm.categoriesZoomThreshold)?_c('PropertyCategoryTile',{key:`performance_${_vm.school.campus_code}_${Math.random()}}`,attrs:{"campus":_vm.school,"property-key":_vm.CAMPUS_PROPERTIES.PAYMENT,"only-icon":"","hide-unknown":""}}):_vm._e()],1)]):_vm._e(),(_vm.currentZoom > 14 && _vm.isFavorite)?_c('Flag',{style:({
      border: '0px solid var(--white)',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -55%)',
    }),attrs:{"icon":"list-check","background":"primary-dark","styling":"icon-round"}}):_vm._e(),(_vm.has360Tour && _vm.currentZoom < 14)?_c('div',{staticClass:"marker-categories__small-marker-text"},[_vm._v(" "+_vm._s(_vm.$t('markers.360'))+" ")]):_vm._e(),_c('MarkerRenderer',{key:_vm.school.uuid,attrs:{"size":_vm.iconSize,"as-dot":_vm.asDot,"selected":_vm.selected,"highlighted":_vm.highlighted,"explored-campus":_vm.exploredCampus,"explored-card":_vm.exploredCard,"show-inner-circle":_vm.showInnerCircle}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }