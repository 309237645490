<template>
  <section
    :class="!mobile ? 'pt-3' : ''"
  >
    <nav class="full-width d-flex flex-row">
      <MobileExplorerHeader
        v-if="mobile"
        :show-campus-search="false"
        @toggleMenu="toggleMenu"
      />
      <v-dialog
        v-if="mobile"
        v-model="showNavigationBar"
        max-width="150"
        transition="slide-x-transition"
        content-class="main-container__row__dialog"
      >
        <CustomMenu
          :in-dashboard="true"
          @closeMenu="setMenuVisibility(false)"
        />
      </v-dialog>

      <div
        v-if="!mobile"
        class="navbar-simulate justify-between"
        :class="mobile ? 'dropdown-navbar-app' : ''"
      >
        <h3
          v-t="'favorite.list'"
          :class="mobile ? 'drop-app' : ''"
          class="navbar-simulate--title"
        />
        <div class="d-flex align-center">
          <button
            type="button"
            class="student-menu__div__more"
            :class="{
              'student-menu__div__more--selected': showFilters,
              'student-menu__div__more--mobile': mobile,
            }"
            @click="showFilters = !showFilters"
          >
            <SvgIcon :icon="getFilterIcons" :size="'20'" style="margin-right: 12px" />
            {{ $t('header.filters') }}
            <div v-if="publicActiveFiltersCount > 0" class="ml-1">
              {{ '( ' + publicActiveFiltersCount + ' )' }}
            </div>
          </button>
        </div>
      </div>
    </nav>
    <v-dialog
      v-model="showFilters"
      max-width="400"
      content-class="filter-modal"
      @click:outside="closeFilter"
    >
      <SchoolsFilter :context="filtersContext" @ready="closeFilter" />
    </v-dialog>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import SchoolsFilter from '@/components/explorer/map/SchoolsFilter.vue';
import CustomMenu from '@/layouts/CustomMenu.vue';
import MobileExplorerHeader from '@/layouts/MobileExplorerHeader.vue';
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';
import { TETHER_FILTER_CONTEXTS as FILTER_CONTEXTS } from '@/constants/filters';

export default {
  name: 'NavbarFavorites',
  components: {
    SchoolsFilter,
    CustomMenu,
    MobileExplorerHeader,
    SvgIcon,
  },
  data() {
    return {
      showFilters: false,
      showNavigationBar: false,
      filtersContext: FILTER_CONTEXTS.FAVORITES,
    };
  },
  computed: {
    ...mapGetters({
      publicActiveFiltersCount: 'filters/publicActiveFiltersCount',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    getFilterIcons() {
      if (this.showFilters) {
        return 'filters-white.svg';
      }
      return 'filters.svg';
    },
  },
  methods: {
    closeFilter() {
      this.showFilters = false;
    },
    setMenuVisibility(visibility) {
      this.showNavigationBar = visibility;
    },
    toggleMenu() {
      this.showNavigationBar = !this.showNavigationBar;
    },
  },
};
</script>
