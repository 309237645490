<template>
  <section class="w-full d-flex flex-column wrap-favorites">
    <NavbarFavorites />
    <div v-if="loading" class="full-width d-flex justify-center pt-24">
      <div class="LoaderBalls">
        <div v-for="index in 3" :key="index" class="LoaderBalls__item" />
      </div>
    </div>
    <FavoritesList
      v-else
      :favorite-schools="filteredFavorites"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FavoritesList from '@/components/list_of_favorites/FavoritesList.vue';
import NavbarFavorites from '@/components/list_of_favorites/NavbarFavorites.vue';
import utils from '@/utils';
import { TETHER_FILTER_CONTEXTS as FILTER_CONTEXTS } from '@/constants/filters';
import { LABELS } from '@/constants/explorer/labels';

export default {
  name: 'ExplorerFavorites',
  components: {
    FavoritesList,
    NavbarFavorites,
  },
  metaInfo() {
    return {
      title: `${this.$tc('dashboard.breadcrumbs.app_name')} | ${this.$tc('dashboard.breadcrumbs.my_list')}`,
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'En la sección de favoritos '
          + 'encontrarás tus colegios de interés y podrás realizar interesantes comparaciones '
          + 'entre ellos.',
      }],
    };
  },
  computed: {
    ...mapGetters({
      loading: 'favorites/loadingSchoolsDetails',
      favoriteSchoolsDetails: 'favorites/listFavoriteSchools',
      filteredFavoritesSchools: 'filters/filteredFavoritesSchools',
      activeFiltersCount: 'filters/activeFiltersCount',
      isGuest: 'authentication/isGuest',
      favoriteGuestList: 'favorites/favoriteGuestList',
    }),
    filteredFavorites() {
      const schools = this.isGuest ? this.favoriteGuestList : this.favoriteSchoolsDetails;
      return (
        this.activeFiltersCount > 0
          ? schools.filter((school) => this.filteredFavoritesSchools.includes(school.uuid))
          : schools
      );
    },
  },
  async created() {
    this.checkLabels({ keysLabels: [LABELS.SPECIALTIES] });
    this.updateFilteredSchools({ context: FILTER_CONTEXTS.FAVORITES });
    // FIXME: This logic is not that good, because it uses the same store for partially loaded data
    // and fully loaded data. It should be refactored to use a different store for each case.
    const getDetails = utils.isGetDetailsFavoritesSchools(this.favoriteSchoolsDetails);

    if (getDetails && this.favoriteSchoolsDetails.length > 0) {
      await this.retrieveListFavorites({ isInLogin: false, getDetails: true });
    } else {
      this.setLoadingSchoolsDetails(false);
    }
  },
  methods: {
    ...mapActions({
      retrieveListFavorites: 'favorites/retrieveListFavorites',
      setLoadingSchoolsDetails: 'favorites/setLoadingSchoolsDetails',
      updateFilteredSchools: 'filters/updateSchools',
      checkLabels: 'explorer/checkLabels',
    }),
  },
};
</script>
<style lang="scss">
.LoaderBalls {
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__item {
    $anim-drt: 0.4s;
    $anim-ease: cubic-bezier(0.6, 0.05, 0.15, 0.95);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #2b5bff;

    &:nth-child(1) {
      animation: bouncing $anim-drt alternate infinite $anim-ease;
    }

    &:nth-child(2) {
      animation: bouncing $anim-drt calc($anim-drt/4) alternate infinite $anim-ease backwards;
    }

    &:nth-child(3) {
      animation: bouncing $anim-drt calc($anim-drt/2) alternate infinite $anim-ease backwards;
    }
  }
}

@keyframes bouncing {
  0% {
    transform: translate3d(0, 10px, 0) scale(1.2, 0.85);
  }

  100% {
    transform: translate3d(0, -20px, 0) scale(0.9, 1.1);
  }
}
</style>
