const broadcastMessage = (message) => {
  const targetWindow = window.parent;
  const isEmbedded = targetWindow !== window;
  const messageWithContext = JSON.parse(JSON.stringify({
    ...message,
    context: 'explored-campus',
  }));
  if (isEmbedded) {
    targetWindow.postMessage(messageWithContext, '*');
  }
};

export { broadcastMessage };
