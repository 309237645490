<template>
  <div class="district-card" :style="zoom > 8 ? '' : 'width: 320px;'">
    <div class="district-card__info__container district-card__info__container--v2">
      <p
        class="district-card__info__container__name mr-1"
        v-t="
          zoom > 8
            ? 'map.district_card.district_card_municipality'
            : 'map.district_card.district_card_department'
        "
      />
      <p class="district-card__info__text__gray font-w">
        {{ name.charAt(0) + name.slice(1).toLowerCase() }}
      </p>
    </div>
    <div
      v-if="zoom > 8 && false"
      class="district-card__info__container district-card__info__container--v1 pl-3"
    >
      <img :src="require('@/assets/iconsNewHaven/icons/schools.svg')" />
      <div class="district-card__info__text">
        {{ getSchoolCount() }}
      </div>
      <p class="district-card__info__text pl-0" v-t="'map.district_card.schools'" />
    </div>
    <div
      v-if="zoom > 8 && false"
      class="district-card__info__container district-card__info__container--v1 pl-3"
    >
      <img :src="require('@/assets/icons/vacancies.svg')" />
      <div class="district-card__info__text">
        {{ getVacanciesCount() }}
      </div>
      <p v-t="'map.district_card.vacancies'" class="district-card__info__text pl-0" />
    </div>
    <div class="district-card__info__container district-card__info__container--v1" v-if="false">
      <div class="district-card__info__text ml-0">x highlighted</div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DistrictCard',
  components: {},
  props: {
    name: {
      type: String,
      default: '',
    },
    zoom: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      communeInfoList: 'institutions/communeInfoList',
    }),
  },
  methods: {
    getSchoolCount() {
      const commune = this.communeInfoList.find(
        (communeInfo) => communeInfo.key.toUpperCase() === this.name,
      );
      return commune.doc_count;
    },
    getVacanciesCount() {
      const commune = this.communeInfoList.find(
        (communeInfo) => communeInfo.key.toUpperCase() === this.name,
      );
      return commune.total_sum.value;
    },
  },
};
</script>
