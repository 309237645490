<template>
  <v-card color="#F5F5F5" class="school-filter">
    <div class="complete-title">
      <v-card-title v-t="'favorite.add-column'" class="dialog-title" />
      <v-btn class="complete-title__icon" icon @click="$emit('close')">
        <v-icon color="#1A0C4C">
          mdi-close
        </v-icon>
      </v-btn>
    </div>
    <v-container class="px-0 school-filter" fluid>
      <div class="mr-4 ml-4 school-filter__profile">
        <ColumnSection
          :title="$t('favorite.admission-title')"
          :options="admissionOptions"
          @setContentArray="setAdmissionFilter"
        />
        <ColumnSection
          :title="$t('favorite.distance-title')"
          :options="distanceOptions"
          @setContentArray="setDistanceFilter"
        />
        <ColumnSection
          :title="$t('favorite.category-title')"
          :options="categoryOptions"
          @setContentArray="setCategoryFilter"
        />
        <ColumnSection
          :title="$t('favorite.partnerships-title')"
          :options="agreementOptions"
          @setContentArray="setAgreementFilter"
        />
        <ColumnSection
          :title="$t('favorite.programs-title')"
          :options="programsOptions"
          @setContentArray="setProgramFilter"
        />
      </div>
    </v-container>

    <FilterActionButtons
      style="padding-bottom: 20px"
      :buttons-text="['map.filter.clean', 'map.filter.apply']"
      in-favorites
      :active-button-loader="false"
      @cleanFilters="cleanFilters"
      @applyFilter="save(listColumnInput)"
    />
  </v-card>
</template>

<script>
import FilterActionButtons from '@/components/organisms/filter/FilterActionButtons.vue';
import ColumnSection from './ColumnSection.vue';

export default {
  name: 'AddColumn',
  components: {
    FilterActionButtons,
    ColumnSection,
  },
  props: {
    listColumnInput: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      admissionSelected: [],
      distanceSelected: [],
      categorySelected: [],
      agreementSelected: [],
      programsSelected: [],
      admissionOptions: [
        {
          name: this.$t('favorite.table_headers.vacancies'), value: 'vacancies', id: 1, isApplied: this.isApplied(1),
        },
        {
          name: this.$t('favorite.table_headers.waitingList'), value: 'waitingList', id: 3, isApplied: this.isApplied(3),
        },
      ],
      distanceOptions: [
        {
          name: this.$t('favorite.table_headers.distance'), value: 'DISTANCE', id: 4, isApplied: this.isApplied(4),
        },
        {
          name: this.$t('favorite.table_headers.carDistance'), value: 'CAR_TIME', id: 5, isApplied: this.isApplied(5),
        },
        {
          name: this.$t('favorite.table_headers.busDistance'), value: 'BUS_TIME', id: 6, isApplied: this.isApplied(6),
        },
      ],
      categoryOptions: [
        {
          name: this.$t('favorite.table_headers.admissions'), value: 'ADMISSION_RISK', id: 7, isApplied: this.isApplied(7),
        },
        {
          name: this.$t('favorite.table_headers.performance'), value: 'PERFORMANCE', id: 8, isApplied: this.isApplied(8),
        },
        {
          name: this.$t('favorite.table_headers.price'), value: 'PAYMENT', id: 9, isApplied: this.isApplied(9),
        },
      ],
      agreementOptions: [
        {
          name: this.$t('favorite.table_headers.agreement'), value: 'agreement', id: 10, isApplied: this.isApplied(10),
        },
        {
          name: this.$t('favorite.table_headers.sector'), value: 'sector', id: 11, isApplied: this.isApplied(11),
        },
      ],
      programsOptions: [
        // {
        //   name: this.$t('favorite.table_headers.area'), value: 'area', id: 12, isApplied: this.isApplied(12),
        // },
        {
          name: this.$t('favorite.table_headers.specialty'), value: 'specialty', id: 13, isApplied: this.isApplied(13),
        },
        // {
        //   name: this.$t('favorite.table_headers.modality'), value: 'modality', id: 14, isApplied: this.isApplied(14),
        // },
      ],
    };
  },
  computed: {
    listColumnSelected() {
      return [
        ...this.admissionSelected,
        ...this.distanceSelected,
        ...this.categorySelected,
        ...this.agreementSelected,
        ...this.programsSelected,
      ];
    },
  },
  mounted() {
    // Initialize selected arrays based on isApplied values for each category of options
    this.initializeSelectedArray(this.admissionOptions, this.admissionSelected);
    this.initializeSelectedArray(this.distanceOptions, this.distanceSelected);
    this.initializeSelectedArray(this.categoryOptions, this.categorySelected);
    this.initializeSelectedArray(this.agreementOptions, this.agreementSelected);
    this.initializeSelectedArray(this.programsOptions, this.programsSelected);
  },
  methods: {
    initializeSelectedArray(optionsArray, selectedArray) {
      selectedArray.splice(0);
      optionsArray
        .filter((option) => this.isApplied(option.id))
        .forEach((option) => selectedArray.push(option.id));
    },
    resetOptions(optionsArray) {
      optionsArray.forEach((option) => {
        this.$set(option, 'isApplied', false);
      });
    },
    cleanFilters() {
      this.admissionSelected = [];
      this.resetOptions(this.admissionOptions);
      this.distanceSelected = [];
      this.categorySelected = [];
      this.agreementSelected = [];
      this.programsSelected = [];
    },
    isApplied(id) {
      return this.listColumnInput.includes(id);
    },
    save() {
      this.$emit('save', this.listColumnSelected);
    },
    setAdmissionFilter(selectedContent) {
      this.admissionSelected = selectedContent;
    },
    setDistanceFilter(selectedContent) {
      this.distanceSelected = selectedContent;
    },
    setCategoryFilter(selectedContent) {
      this.categorySelected = selectedContent;
    },
    setAgreementFilter(selectedContent) {
      this.agreementSelected = selectedContent;
    },
    setProgramFilter(selectedContent) {
      this.programsSelected = selectedContent;
    },
  },
};

</script>
