<template>
  <DashboardTemplate :sections="sections" :grid-area-component="gridAreaComponentDP" @setPanel="setPanel">
    <template #navbarItems>
      <div class="dashboard-navbar__route">
        {{ $t("dashboard.digital_profile.title") }}
      </div>
      <div v-if="currentPanelTitle != ''" class="dashboard-navbar__separator">
        /
      </div>
      <div
        v-if="currentPanelTitle != ''"
        class="dashboard-navbar__route dashboard-navbar__clickable__underlined"
      >
        {{ currentPanelTitle }}
      </div>
    </template>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from '@/components/dashboard/Template.vue';

export default {
  name: 'DigitalProfileView',
  components: { DashboardTemplate },
  metaInfo() {
    return {
      title: `${this.$tc('dashboard.breadcrumbs.app_name')} | ${this.$tc('dashboard.breadcrumbs.digital_profile')}`,
      meta: [{ name: 'robots', content: 'noindex, nofollow' }],
    };
  },
  data() {
    return {
      currentPanelTitle: '',
      panelName: '',
      gridAreaComponentDP: '"panel-selector panel-selector panel-editor panel-editor panel-editor panel-editor" "panel-selector panel-selector panel-editor panel-editor panel-editor panel-editor" "panel-selector panel-selector panel-editor panel-editor panel-editor panel-editor" "panel-selector panel-selector panel-editor panel-editor panel-editor panel-editor"',
    };
  },
  computed: {
    sections() {
      const sections = [
        {
          name: 'panel-selector',
          title: this.$t('dashboard.digital_profile.instructions'),
          permissions: ['headmaster'],
          mobileHeight: '130px',
          row: '1/3',
          col: '1/2',
          background: 'white',
          bodyHeight: 'calc(100% - 40px)',
          gridArea: 'panel-selector',
        },
        {
          name: 'panel-editor',
          title: '',
          showTitle: false,
          mobileHeight: 'calc(100vh - 170px)',
          permissions: ['headmaster'],
          row: '1/3',
          col: '2/4',
          sectionProps: {
            currentPanel: this.panelName,
          },
          paddingSection: '20px 24px',
          gridArea: 'panel-editor',
        },
      ];
      return sections;
    },
  },
  methods: {
    setPanel(panelName, panelTitle) {
      this.currentPanelTitle = panelTitle;
      this.panelName = panelName;
    },
  },
};
</script>
