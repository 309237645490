<template>
  <section class="navbar-favorites py-3">
    <nav class="full-width d-flex flex-row">
      <img
        v-if="isMobile"
        class="dashboard-navbar-icon icon-app"
        src="@/assets/icons/hamburger-dashboard.svg"
        alt=""
        @click="setMenuVisibility(true)"
        @keydown="setMenuVisibility(true)"
      />
      <v-dialog
        v-if="isMobile"
        v-model="showNavigationBar"
        max-width="150"
        transition="slide-x-transition"
        content-class="main-container__row__dialog"
      >
        <CustomMenu
          :in-dashboard="true"
          @closeMenu="setMenuVisibility(false)"
        />
      </v-dialog>

      <div
        class="navbar-favorites__username"
        :class="isMobile ? 'user-navbar-app' : ''"
      >
        {{ username }}
      </div>
      <div
        class="navbar-favorites__options"
        :class="isMobile ? 'dropdown-navbar-app' : ''"
      >
        <h3
          v-t="'application.title'"
          :class="isMobile ? 'drop-app' : ''"
          class="navbar-favorites__title"
        />

        <div class="navbar-favorites__options__students">
          <v-select
            class="elevation-0"
            hide-details
            :items="studentList"
            :value="currentStudent"
            item-text="name"
            item-value="uuid"
            solo
            dense
            height="36"
            color="#2b5bff"
            :menu-props="{ bottom: true, offsetY: true }"
            @change="changeCurrentStudent"
          >
            <template #selection="{ item }">
              <span class="navbar-favorites__options__students--name">
                {{ item.name.split(" ", 1)[0] }}
              </span>
            </template>
          </v-select>
        </div>
      </div>
    </nav>
  </section>
</template>

<script>
import CustomMenu from '@/layouts/CustomMenu.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'NavbarApplication',
  components: {
    CustomMenu,
  },
  data() {
    return {
      showNavigationBar: false,
    };
  },
  computed: {
    ...mapGetters({
      username: 'authentication/userUsername',
      studentList: 'authentication/students',
      currentStudent: 'authentication/currentStudent',
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    const hasCurrentStudent = JSON.stringify(this.currentStudent) !== '{}';
    if (!hasCurrentStudent && this.studentList.length > 0) {
      this.changeCurrentStudent(this.studentList[0].uuid);
    }
  },
  methods: {
    ...mapActions({
      setCurrentStudent: 'authentication/setCurrentStudent',
      retrieveCampuses: 'institutions/retrieveCampuses',
      retrieveExploredCampuses: 'institutions/retrieveExploredCampuses',
      retrieveExploredSchoolCard: 'institutions/retrieveExploredSchoolCard',
      retrieveFavorites: 'favorites/retrieveListFavorites',
      raiseError: 'utils/error',
    }),
    changeCurrentStudent(studentUUID) {
      const student = this.studentList.find((st) => st.uuid === studentUUID);
      // this.$emit('uuid', student.uuid);
      this.setCurrentStudent({ student, hotjar: this.$hj })
        // .then(() => this.retrieveFavorites())
        .then(() => this.retrieveExploredCampuses())
        .then(() => this.retrieveExploredSchoolCard())
        .then(() => this.retrieveCampuses({ callback: null }))
        .catch((error) => this.raiseError(error));
    },
    setMenuVisibility(visibility) {
      this.showNavigationBar = visibility;
    },
  },
};
</script>
