<template>
  <section class="simulation-results" :class="{ 'px-5': isMobile }">
    <template v-if="loading">
      <v-skeleton-loader
        type="article, image, list-item-three-line, image, actions"
        class="pt-16"
      />
    </template>
    <template v-else>
      <v-btn icon class="simulation-results__close" @click="$emit('closeSimulationResults')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <!-- title -->
      <h1 v-t="'simulation.results_historical.title'" class="mt-1 title-poppins primary-dark" />

      <!-- resutls for years -->
      <div
        class="simulation-results__years mt-8"
        :style="isMobile ? 'flex-direction: column' : 'flex-direction: row'"
      >
        <template v-for="year in getSimulableYears()">
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <input :id="year" v-model="simulationYear" type="radio" :value="year" />
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <label
            v-t="{ path: 'simulation.results_historical.year', args: { year } }"
            :for="year"
          />
        </template>
      </div>

      <!-- description -->
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="description-poppins primary-dark mt-5" v-html="textSummary()" />

      <!-- In case there's no data for a specific year, we display an warning -->
      <div v-if="!checkDataForYear()">
        <div class="simulation-results__probability mt-8">
          <div class="simulation-results__probability--description w-full mx-2">
            <h1 class="description-poppins primary-dark text-left font-semibold">
              <v-icon normal>
                mdi-alert-outline
              </v-icon>
              {{ $t("simulation.results_historical.no_data.title") }}
            </h1>
            <p class="caption-inter primary-dark mt-2">
              {{ $t("simulation.results_historical.no_data.description") }}
            </p>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="simulation-results__chart mt-16" :class="{ 'flex-column': isMobile }">
          <!-- chart -->
          <div class="simulation-results__chart__percentage" :class="{ 'w-full': isMobile }">
            <p class="simulation-results__chart__percentage--number">
              {{ getChartPercentage() }} %
            </p>
            <span
              v-t="'simulation.results_historical.probability_text'"
              class="simulation-results__chart__percentage--text"
            />
          </div>
          <!-- chart description -->
          <div class="simulation-results__chart-description" :class="{ 'w-full mt-5': isMobile }">
            <h2
              v-t="'simulation.results_historical.feedback.title'"
              class="title-poppins primary-dark text-left"
            />
            <p class="description-inter primary-dark mt-2">
              {{ textDescriptionFeedback() }}
            </p>
          </div>
        </div>

        <!-- description probability -->
        <div v-if="getUnmatched() > 30">
          <section class="simulation-results__probability mt-8">
            <div class="simulation-results__probability--description">
              <h1 class="description-poppins primary-dark text-left">
                <v-icon small>
                  mdi-alert-outline
                </v-icon>
                {{ $t("simulation.results_historical.probability.title") }}
                <span
                  v-t="'simulation.results_historical.probability.not'"
                  class="primary-danger simulation-results__probability--high"
                />
                {{ $t("simulation.results_historical.probability.next_title") }}
              </h1>

              <p class="caption-inter primary-dark mt-2">
                {{ textDescriptionProbability() }}
              </p>
            </div>
            <div class="simulation-results__probability--total">
              {{ getUnmatched() }} %
            </div>
          </section>
        </div>

        <!-- title of schools -->
        <h1
          v-t="'simulation.results_historical.probability.title_school'"
          class="title-poppins primary-dark text-left mt-10"
        />
        <v-expansion-panels class="pb-7">
          <v-expansion-panel v-for="(school, index) in listSchoolsWithData()" :key="index">
            <v-expansion-panel-header disable-icon-rotate expand-icon="" class="p-0 mt-2">
              <template #default="{ open }">
                <section class="simulation-results__school">
                  <!-- Rank -->
                  <span class="title-poppins primary-medium simulation-results__school--rank">
                    {{ index + 1 }}
                  </span>
                  <!-- Description School -->
                  <div class="simulation-results__school--container">
                    <div class="simulation-results__school--description">
                      <picture v-if="school.image">
                        <img :src="school.image[0].image_link" alt="thumbnail" />
                      </picture>
                      <div>
                        <h3 class="description-poppins primary-dark">
                          {{ school.campus.campus_name }}
                        </h3>
                        <span class="caption-inter text-text-neutral-600">
                          {{ school.program.grade_name }}
                        </span>
                        <span class="caption-inter text-text-neutral-600">
                          {{ school.program.shift_label }}
                        </span>
                      </div>
                    </div>
                    <!-- Probability -->
                    <aside class="simulation-results__school--probability">
                      <span>
                        {{ (school.ratex * 100).toFixed(0) }} %
                      </span>
                    </aside>
                    <aside class="simulation-results__school--action">
                      <v-btn icon @click="open = !open">
                        <v-icon>{{ open ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                      </v-btn>
                    </aside>
                  </div>
                </section>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="simulation-results__school--seats"
              :style="isMobile ? 'padding: 1rem 0.5rem !important' : 'padding: 1rem 4rem !important'"
            >
              <div class="w-full d-flex flex-column">
                <div class="w-full d-flex align-center">
                  <img src="@/assets/simulation/available.svg" alt="available" width="22">
                  <span
                    v-t="{
                      path: 'simulation.results_historical.vacancies.available',
                      args: { total: school.stats.seats, year: simulationYear },
                    }"
                    class="caption-inter text-neutral-850 ml-2"
                  />
                </div>
                <div class="w-full d-flex align-center mt-2">
                  <img src="@/assets/simulation/priority.svg" alt="priority" width="22">
                  <span
                    v-t="{
                      path: 'simulation.results_historical.vacancies.priority',
                      args: { total: school.stats.n_considered_above },
                    }"
                    class="caption-inter text-neutral-850 ml-2"
                  />
                </div>
                <div class="w-full d-flex align-center mt-2">
                  <img src="@/assets/simulation/same-priority.svg" alt="same priority" width="22" />
                  <span
                    v-t="{
                      path: 'simulation.results_historical.vacancies.same_priority',
                      args: { total: school.stats.n_considered },
                    }"
                    class="caption-inter text-neutral-850 ml-2"
                  />
                </div>
                <div class="w-full d-flex align-center mt-2">
                  <img src="@/assets/simulation/assigned-same-priority.svg" alt="assigned same priority" width="22" />
                  <span
                    v-t="{
                      path: 'simulation.results_historical.vacancies.assigned_with_priority',
                      args: { total: school.stats.n_assigned },
                    }"
                    class="caption-inter text-neutral-850 ml-2"
                  />
                </div>
                <div class="w-full d-flex align-center mt-2">
                  <img src="@/assets/simulation/no-assigned.svg" alt="assigned same priority" width="22" />
                  <span
                    v-t="{
                      path: 'simulation.results_historical.vacancies.not_assigned',
                      args: { total: availableSeats(school) },
                    }"
                    class="caption-inter text-neutral-850 ml-2"
                  />
                </div>

                <span
                  class="caption text-neutral-600 mt-5 text-left"
                >
                  {{ textDisclaimer(index) }}
                </span>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="pb-5">
          <v-expansion-panel v-for="(school, index) in listSchoolsWithNoData()" :key="index">
            <v-expansion-panel-header disable-icon-rotate expand-icon="" class="p-0 mt-2">
              <section class="simulation-results__school">
                <!-- Rank -->
                <span class="title-poppins primary-medium simulation-results__school--rank">
                  -
                </span>
                <!-- Description School -->
                <div class="simulation-results__school--container">
                  <div class="simulation-results__school--description">
                    <picture v-if="school.image">
                      <img :src="school.image[0].image_link" alt="thumbnail" />
                    </picture>
                    <div>
                      <h3 class="description-poppins primary-dark">
                        {{ school.name }}
                      </h3>
                      <span class="caption-inter text-text-neutral-600">
                        <v-icon small>
                          mdi-alert-outline
                        </v-icon>
                        {{ $t("simulation.results_historical.no_data.single_program") }}
                      </span>
                    </div>
                  </div>
                  <!-- Probability -->
                  <aside class="simulation-results__school--probability">
                    <span>
                      -
                    </span>
                  </aside>
                </div>
              </section>
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { TENANT_CONFIGURATION } from '@/constants/tenant';

export default {
  name: 'SimulationResults',
  data() {
    return {
      simulationYear: null,
      TENANT_CONFIGURATION,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'simulation/loading',
      simulateList: 'simulation/simulateList',
      simulationResults: 'simulation/simulationResults',
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    simulationResults() {
      this.setLastYear();
    },
  },
  mounted() {
    this.simulateRisk();
  },
  methods: {
    ...mapActions({
      simulateRisk: 'simulation/simulateRisk',
    }),
    getSimulableYears() {
      const [startYear, endYear] = this.TENANT_CONFIGURATION.SETTINGS.SIMULATION.YEAR_RANGE;
      const yearsToSimulate = Array.from({ length: endYear - startYear + 1 }, (_, i) => endYear - i);
      return yearsToSimulate;
    },
    checkDataForYear() {
      return this.simulationResults.find((data) => data.api_response.year === this.simulationYear) !== undefined;
    },
    availableSeats(school) {
      const nRejected = school.stats.n_considered_tot - school.stats.seats;

      return nRejected < 0 ? 0 : nRejected;
    },
    getUnmatched() {
      return this.simulationYear !== null && this.checkDataForYear() ? (this.simulationResults
        .find((data) => data.api_response.year === this.simulationYear).api_response.unmatched * 100).toFixed(0) : 0;
    },
    getChartPercentage() {
      return 100 - this.getUnmatched();
    },
    setLastYear() {
      // Results are ordered by decreasing year, so the first one is the most recent
      this.simulationYear = this.simulationResults[0].api_response.year;
    },
    messageCodes() {
      return this.simulationYear !== null && this.checkDataForYear() ? this.simulationResults
        .find((data) => data.api_response.year === this.simulationYear).api_response.messages : [];
    },
    listSchoolsWithData() {
      return this.simulationYear !== null && this.checkDataForYear() ? this.simulationResults
        .find((data) => data.api_response.year === this.simulationYear).api_response.portfolio : [];
    },
    listSchoolsWithNoData() {
      const listNoData = [];
      this.simulateList.forEach((school) => {
        if (this.listSchoolsWithData().find((data) => data.campus.campus_code === school.campus_code) === undefined) {
          listNoData.push(school);
        }
      });
      return listNoData;
    },
    textSummary() {
      const code = this.messageCodes()?.overall_years_summary?.msgcode ?? '';
      return this.$t('simulation.results_historical.summary')[code];
    },
    textDescriptionFeedback() {
      const code = this.messageCodes()?.overall_within_year?.msgcode ?? '';
      return this.$t('simulation.results_historical.feedback.description')[code];
    },
    textDescriptionProbability() {
      const code = this.messageCodes()?.overall_whyRisky_within_year?.msgcode ?? '';
      return this.$t('simulation.results_historical.probability.description')[code];
    },
    textDisclaimer(index) {
      const codeWithinYear = this.messageCodes()?.portfolio[index]?.specificApp_within_year?.msgcode ?? '';
      const codeWhyRisk = this.messageCodes()?.portfolio[index]?.specificApp_whyRisky_within_year?.msgcode ?? '';

      return `${this.$t('simulation.results_historical.disclaimer')[codeWithinYear] ?? ''
      } ${this.$t('simulation.results_historical.disclaimer-concat')[codeWhyRisk] ?? ''}` ?? '';
    },
  },
};
</script>
<style scoped>
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent !important;
  border-bottom: none !important;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border-color: transparent !important;
}
</style>
