<template>
  <DashboardTemplate
    :sections="comingSoon ? sectionsComingSoon : sections"
    :grid-area-component="comingSoon ? gridAreaComponentNComingSoon : gridAreaComponentN"
  >
    <template #navbarItems>
      <div class="dashboard-navbar__route">
        {{ $t("dashboard.messaging.title") }}
      </div>
    </template>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from '@/components/dashboard/Template.vue';

export default {
  name: 'NotificationsView',
  components: { DashboardTemplate },
  metaInfo() {
    return {
      title: `${this.$tc('dashboard.breadcrumbs.app_name')} | ${this.$tc('dashboard.breadcrumbs.notifications')}`,
      meta: [{ name: 'robots', content: 'noindex, nofollow' }],
    };
  },
  data() {
    return {
      sections: [
        {
          name: 'list-of-messages',
          title: 'Mensajes',
          showTitle: false,
          background: 'transparent',
          permissions: ['headmaster'],
          paddingSection: '0px',
          gridArea: 'list-of-messages',
        },
        {
          name: 'messages-categories',
          title: 'Categorías',
          showTitle: true,
          background: 'transparent',
          permissions: ['headmaster'],
          gridArea: 'messages-categories',
        },
      ],
      sectionsComingSoon: [
        {
          name: 'coming-soon',
          title: this.$t('dashboard.coming-soon.title'),
          showTitle: false,
          background: 'transparent',
          permissions: ['headmaster'],
          gridArea: 'coming-soon',
          paddingSection: '0px',
          sectionProps: {
            icon: 'message-box.svg',
            subtitle: this.$t('dashboard.coming-soon.messages.description'),
          },
        },
      ],
      gridAreaComponentN: '"list-of-messages list-of-messages list-of-messages list-of-messages messages-categories messages-categories" "list-of-messages list-of-messages list-of-messages list-of-messages messages-categories messages-categories" "list-of-messages list-of-messages list-of-messages list-of-messages messages-categories messages-categories" "list-of-messages list-of-messages list-of-messages list-of-messages messages-categories messages-categories"',
      gridAreaComponentNComingSoon: '"coming-soon coming-soon coming-soon coming-soon coming-soon coming-soon" "coming-soon coming-soon coming-soon coming-soon coming-soon coming-soon" "coming-soon coming-soon coming-soon coming-soon coming-soon coming-soon" "coming-soon coming-soon coming-soon coming-soon coming-soon coming-soon"',
      comingSoon: true,
    };
  },
};
</script>
