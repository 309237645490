<template>
  <div
    class="selector-buttons"
    :class="{
      'selector-buttons--vertical': verticalSelectors,
      'selector-buttons--mobile': mobile,
    }"
  >
    <div
      v-for="info in selectorArray"
      :key="info.id"
      :style="getSelectorStyles"
    >
      <SideSelectionButtons
        :text="info.label"
        :completed="info.completed"
        :selected="info.selected"
        :vertical-buttons="verticalSelectors"
        :small="mobile"
        @setValue="$emit('setValue', info.id)"
      />
    </div>
  </div>
</template>

<script>
import SideSelectionButtons from '@/components/atoms/buttons/SideSelectionButtons.vue';

export default {
  name: 'SelectionButtons',
  components: { SideSelectionButtons },
  props: {
    selectorArray: {
      type: Array,
      default() {
        return [];
      },
    },
    verticalSelectors: {
      type: Boolean,
      default: false,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getSelectorStyles() {
      if (this.verticalSelectors) {
        return 'width: max-content';
      }
      if (this.mobile) {
        return 'width: 100%';
      }
      return 'margin-right: 16px';
    },
  },
};
</script>
