import { repeatUntilLength } from '@/utils/strings';
/**
 *
 * @param {string} hex - Hex color code in the format #000000
 * @param {*} alpha - Alpha value between 0 and 1 (opacity)
 * @returns {string} - RGBA color code in the format rgba(0, 0, 0, 1)
 */
const hexToRGBA = (hex, alpha = 1) => {
  const hexRegex = /#?(?<r>\w{1,2})(?<g>\w{1,2})(?<b>\w{1,2})/g;
  const hexMatch = hexRegex.exec(hex);
  const r = parseInt(repeatUntilLength(hexMatch.groups.r, 2), 16);
  const g = parseInt(repeatUntilLength(hexMatch.groups.g, 2), 16);
  const b = parseInt(repeatUntilLength(hexMatch.groups.b, 2), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const getComputedColorValue = (variableName) => {
  const style = getComputedStyle(document.documentElement);
  const color = style.getPropertyValue(variableName);
  return color;
};

export { hexToRGBA, getComputedColorValue };
