<template>
  <SelectGrades
    is-in-map
    :in-student-flow="!!currentStudent?.uuid"
    @backStep="$emit('closeModal')"
    @sendGrade="sendGrade"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SelectGrades from '@/components/shared/SelectGrades.vue';

export default {
  name: 'GradeFlow',
  components: {
    SelectGrades,
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
      isGuest: 'authentication/isGuest',
      guestLocation: 'authentication/guestLocation',
    }),
  },
  methods: {
    ...mapActions({
      setIsGuestClickFirstTime: 'authentication/setIsGuestClickFirstTime',
      setGuestData: 'authentication/setGuestData',
      retrieveCampuses: 'institutions/retrieveCampuses',
    }),
    sendGrade(selectedOption) {
      if (this.isGuest) {
        this.setGuestData({
          location: JSON.parse(JSON.stringify(this.guestLocation)),
          grades: selectedOption.grades,
        }).then(() => {
          this.retrieveCampuses({});
        });
        if (!this.isGuestClickFirstTime || !this.isGuestClickFirstTime.grade) {
          this.setIsGuestClickFirstTime({ property: 'grade' });
        }
      }
      return this.$emit('closeModal');
    },
  },
};
</script>
