var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":_vm.size + 35,"height":_vm.size + 35,"viewBox":`${-(_vm.outerRadius - 20)} ${-(_vm.outerRadius - 20)} ${(_vm.outerRadius + 60) * 2} ${(_vm.outerRadius + 60) * 2}`,"fill":"none"}},[_c('mask',{attrs:{"id":`mask__${_vm._uid}`}},[_c('rect',{attrs:{"x":"-22%","y":"-22%","width":"100%","height":"100%","fill":"white"}}),_c('circle',{attrs:{"cx":_vm.outerRadius,"cy":_vm.outerRadius,"r":_vm.innerRadius + 1,"fill":"black"}})]),_c('g',{attrs:{"clip-path":`url(#doughnut-layout__${_vm._uid})`,"mask":!_vm.asDot ? `url(#mask__${_vm._uid})` : ''}},[_c('circle',{style:({
        fill: _vm.mainColorRGBA,
        stroke: _vm.mainColor,
        'stroke-width': (_vm.selected || _vm.exploredCard || _vm.exploredCampus) ? 22 : 8,
      }),attrs:{"id":"outer-ring","cx":_vm.outerRadius,"cy":_vm.outerRadius,"r":_vm.asDot ? 0 : _vm.outerRadius}}),(_vm.selected && !_vm.asDot)?_c('circle',{style:({
        stroke: _vm.borderColorRGBA,
        'stroke-width': 30,
      }),attrs:{"id":"outer-border","cx":_vm.outerRadius,"cy":_vm.outerRadius,"r":_vm.outerRadius + 26}}):_vm._e(),_c('circle',{style:({ fill: _vm.showInnerCircle !== -1 ? _vm.innerCircleHexColor : _vm.mainColor }),attrs:{"id":"inner-border","cx":_vm.outerRadius,"cy":_vm.outerRadius,"r":_vm.innerRadius}}),_c('circle',{style:({
        stroke: _vm.innerCircleHexColor,
        'stroke-width': 1,
      }),attrs:{"id":"inner-ring","cx":_vm.outerRadius,"cy":_vm.outerRadius,"r":_vm.showInnerCircle !== -1 ? _vm.innerRingRadius : 0,"fill":_vm.asDot ? _vm.mainColorRGBA : _vm.innerCircleRGBA}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }