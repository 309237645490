<template>
  <div
    class="application-selection-modal"
    :class="{ 'application-selection-modal--mobile': mobile }"
  >
    <ModalHeader
      :content-sections="['close', 'title', 'subtitle']"
      :title-text="'user_register.student_selection.simulate.title'"
      :subtitle-text="'user_register.student_selection.simulate.subtitle'"
      center-subtitle
      close-no-margin
      @close="$emit('closeModal')"
      @back="goBack()"
    />
    <section
      class="application-selection-modal--sections"
      :class="{ 'application-selection-modal--sections--mobile': mobile }"
    >
      <ModalContent
        :content-sections="['subtitle', 'selectionButton', 'instructions']"
        :subtitle-text="'user_register.student_selection.simulate.student_title'"
        subtitle-light
        row-instructions
        selection-button-narrow
        selection-button-multi-icon
        :selection-button-click-disabled="isGuest || students.length === 0"
        :instruction-text="'user_register.student_selection.simulate.optional'"
        :selection-button-info="studentsSelectInfo"
        @setSelectionButtonValue="setActiveStudent"
      />
    </section>
    <section
      class="application-selection-modal--sections"
      :class="{ 'application-selection-modal--sections--mobile': mobile }"
    >
      <ModalContent
        :content-sections="['subtitle']"
        :subtitle-text="'user_register.student_selection.simulate.grade_title'"
        subtitle-light
        subtitle-no-margin
      />
      <SelectGrades
        only-grades
        button-grades
        in-student-flow
        button-wrap
        :change-students="studentChange"
        @setGrade="setGradeSelected"
      />
    </section>
    <section
      class="application-selection-modal--sections"
      :class="{ 'application-selection-modal--sections--mobile': mobile }"
    >
      <ModalContent
        :content-sections="['subtitle', 'instructions']"
        :subtitle-text="'user_register.student_selection.simulate.priority_title'"
        row-instructions
        :instruction-text="'user_register.student_selection.simulate.optional'"
        subtitle-light
        subtitle-no-margin
      />
      <TextAtom
        :value="$t('user_register.student_selection.simulate.priority_description')"
        :color="'primary-dark'"
        font="poppins"
        weight="400"
        size="normal"
        class="text-left"
      />
      <section style="margin-top: 20px">
        <section
          class="d-flex"
          style="margin-top: 20px"
          :style=" mobile ? 'margin: 20px 0 20px 0' : 'margin-top: 20px'"
        >
          <SectionSelectors
            :selector-array="firstRowPrioritiesSelector"
            :mobile="mobile"
            @setValue="setSelectedPriority"
          />
        </section>
        <CampusSelection
          v-if="selectedSelector === 1"
          in-simulation
          class="application-selection-modal--sections--priorities"
          :class="{ 'application-selection-modal--sections--priorities--mobile': mobile }"
          pre-fill
          style="border-radius: 0px 8px 8px 8px !important;"
          @nextStep="setCompletedPriorities(1)"
          @backStep="selectedSelector = 0"
        />
        <OldCampusPriority
          v-if="selectedSelector === 2"
          class="application-selection-modal--sections--priorities"
          :class="{ 'application-selection-modal--sections--priorities--mobile': mobile }"
          in-simulation
          @backStep="selectedSelector -= 1"
          @nextStep="setCompletedPriorities(2)"
        />
        <BrotherInCampus
          v-if="selectedSelector === 3"
          only-campus
          class="application-selection-modal--sections--priorities"
          :class="{ 'application-selection-modal--sections--priorities--mobile': mobile }"
          pre-fill
          @backStep="selectedSelector -= 1"
          @nextStep="setCompletedPriorities(3)"
        />
      </section>
      <section style="margin-top: 20px">
        <section
          class="d-flex"
          style="margin-top: 20px"
          :style=" mobile ? 'margin: 20px 0 20px 0' : 'margin-top: 20px'"
        >
          <SectionSelectors
            :selector-array="secondRowPrioritiesSelector"
            :mobile="mobile"
            @setValue="setSelectedPriority"
          />
        </section>
        <CampusSelection
          v-if="selectedSelector === 4"
          in-simulation
          class="application-selection-modal--sections--priorities"
          :class="{ 'application-selection-modal--sections--priorities--mobile': mobile }"
          pre-fill
          parent-campus
          style="border-radius: 0px 8px 8px 8px !important;"
          @nextStep="setCompletedPriorities(4)"
          @backStep="selectedSelector -= 1"
        />
        <BooleanPriorities
          v-if="selectedSelector === 5"
          class="application-selection-modal--sections--priorities"
          :class="{ 'application-selection-modal--sections--priorities--mobile': mobile }"
          in-simulation
          :boolean-priorities="['neep']"
          @backStep="selectedSelector -= 1"
          @nextStep="setCompletedPriorities(5)"
        />
      </section>
      <section style="margin-top: 20px">
        <section
          class="d-flex"
          style="margin-top: 20px"
          :style=" mobile ? 'margin: 20px 0 20px 0' : 'margin-top: 20px'"
        >
          <SectionSelectors
            :selector-array="thirdRowPrioritySelector"
            :mobile="mobile"
            @setValue="setSelectedPriority"
          />
        </section>
        <BooleanPriorities
          v-if="selectedSelector === 6"
          class="application-selection-modal--sections--priorities"
          :class="{ 'application-selection-modal--sections--priorities--mobile': mobile }"
          in-simulation
          :boolean-priorities="['sep']"
          @backStep="selectedSelector -= 1"
          @nextStep="setCompletedPriorities(6)"
        />
      </section>
    </section>
    <MainButton
      class=" d-flex fill-width justify-center"
      :is-active="Object.keys(gradeSelected).length > 0"
      :text="'user_register.brother_in_campus.confirm'"
      style="padding: 0 100px; margin-top: 68px;"
      @continue="Object.keys(gradeSelected).length > 0 ? confirm() : null"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MainButton from '@/components/atoms/buttons/MainButton.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import SectionSelectors from '@/components/molecules/sections/SectionSelectors.vue';
import BooleanPriorities from '@/components/organisms/register/BooleanPriorities.vue';
import BrotherInCampus from '@/components/organisms/register/BrotherInCampus.vue';
import CampusSelection from '@/components/organisms/register/CampusSelection.vue';
import OldCampusPriority from '@/components/organisms/register/OldCampusPriority.vue';
import SelectGrades from '@/components/shared/SelectGrades.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
// import LocationFlow from '@/components/templates/LocationFlow.vue';

export default {
  name: 'ApplicationStudentSelectionModal',
  components: {
    ModalHeader,
    ModalContent,
    SelectGrades,
    SectionSelectors,
    BrotherInCampus,
    OldCampusPriority,
    CampusSelection,
    MainButton,
    BooleanPriorities,
    TextAtom,
  },
  data() {
    return {
      studentsSelectInfo: [],
      selectedSelector: 0,
      prioritiesCompleted: [],
      gradeSelected: {},
      studentChange: 1,
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
      students: 'authentication/students',
      simulateFromPath: 'simulation/simulateFromPath',
      guestSimulationData: 'simulation/guestSimulationData',
      isGuest: 'authentication/isGuest',
    }),
    firstRowPrioritiesSelector() {
      const selector = [
        {
          id: 1,
          label: 'user_register.student_selection.simulate.priority_labels.current_campus',
          completed: this.prioritiesCompleted.includes(1),
          selected: this.selectedSelector === 1,
        },
        {
          id: 2,
          label: 'user_register.student_selection.simulate.priority_labels.old_campus',
          completed: this.prioritiesCompleted.includes(2),
          selected: this.selectedSelector === 2,
        },
        {
          id: 3,
          label: 'user_register.student_selection.simulate.priority_labels.brother',
          completed: this.prioritiesCompleted.includes(3),
          selected: this.selectedSelector === 3,
        },
      ];
      return selector;
    },
    secondRowPrioritiesSelector() {
      const selector = [
        {
          id: 4,
          label: 'user_register.student_selection.simulate.priority_labels.legalGuardian',
          completed: this.prioritiesCompleted.includes(4),
          selected: this.selectedSelector === 4,
        },
        {
          id: 5,
          label: 'user_register.student_selection.simulate.priority_labels.neep',
          completed: this.prioritiesCompleted.includes(5),
          selected: this.selectedSelector === 5,
        },
      ];
      return selector;
    },
    thirdRowPrioritySelector() {
      const selector = [
        {
          id: 6,
          label: 'user_register.student_selection.simulate.priority_labels.sep',
          completed: this.prioritiesCompleted.includes(6),
          selected: this.selectedSelector === 6,
        },
      ];
      return selector;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    hasCurrentCampusInfo() {
      const { enrollment: [guestSimulationCurrentCampus] = [] } = this.guestSimulationData;
      if (this.currentStudent && Object.keys(this.currentStudent).length > 0) {
        return !!(
          this.currentStudent.current_enrollment_priority
          && this.currentStudent.current_enrollment_priority.institution_code
        );
      }
      if (this.isGuest && guestSimulationCurrentCampus) {
        return true;
      }
      return false;
    },
    hasPreviousCampusInfo() {
      const { former_student: [guestSimulationOldCampus] = [] } = this.guestSimulationData;
      if (this.currentStudent && Object.keys(this.currentStudent).length > 0) {
        return !!(
          this.currentStudent.previus_enrollment_priority
          && this.currentStudent.previus_enrollment_priority.institution_code
        );
      }
      if (this.isGuest && guestSimulationOldCampus) {
        return true;
      }
      return false;
    },
    hasBrotherInCampus() {
      const { static_sibling: guestSimulationBrotherInCampus = [] } = this.guestSimulationData;
      if (this.currentStudent && Object.keys(this.currentStudent).length > 0) {
        return !!(this.currentStudent.siblings_priority && this.currentStudent.siblings_priority.length > 0);
      }
      if (this.isGuest && guestSimulationBrotherInCampus?.length > 0) {
        return true;
      }
      return false;
    },
    hasParentInCampus() {
      const { parent_worker: [guestSimulationParentCampus] = [] } = this.guestSimulationData;
      if (this.currentStudent && Object.keys(this.currentStudent).length > 0) {
        return !!(
          this.currentStudent.parent_enrollment_priority
          && this.currentStudent.parent_enrollment_priority.institution_code
        );
      }
      if (this.isGuest && guestSimulationParentCampus) {
        return true;
      }
      return false;
    },
    hasSep() {
      const { sep: guestSimulationSep } = this.guestSimulationData;
      if (this.currentStudent && Object.keys(this.currentStudent).length > 0) {
        return this.currentStudent?.prioritys?.appl_prios?.has_sep;
      }
      if (this.isGuest && guestSimulationSep) {
        return true;
      }
      return false;
    },
    hasNeep() {
      const { nee: guestSimulationNeep } = this.guestSimulationData;
      if (this.currentStudent && Object.keys(this.currentStudent).length > 0) {
        return this.currentStudent.prioritys?.appl_prios?.has_neep;
      }
      if (this.isGuest && guestSimulationNeep) {
        return true;
      }
      return false;
    },
    isSomeStudentActive() {
      return this.studentsSelectInfo.find((student) => student.isActive === true);
    },
  },
  watch: {
    currentStudent: {
      handler() {
        this.selectorPriorityPrefill();
      },
      deep: true,
    },
    guestSimulationData: {
      handler() {
        this.selectorPriorityPrefill();
      },
      deep: true,
    },
  },
  mounted() {
    this.getSelectionInfo();
    this.selectorPriorityPrefill();
  },
  methods: {
    ...mapActions({
      setStudentGrades: 'newStudentRegister/setStudentGrades',
      setFastRegisterGrade: 'userRegister/setFastRegisterGrade',
      setCurrentStudent: 'authentication/setCurrentStudent',
      setGuestData: 'authentication/setGuestData',
      setSimulationModalCompleted: 'simulation/setSimulationModalCompleted',
      setIsGuestClickFirstTime: 'authentication/setIsGuestClickFirstTime',
    }),
    selectorPriorityPrefill() {
      this.prioritiesCompleted = [];
      if (this.hasCurrentCampusInfo) {
        this.prioritiesCompleted.push(1);
      }
      if (this.hasPreviousCampusInfo) {
        this.prioritiesCompleted.push(2);
      }
      if (this.hasBrotherInCampus) {
        this.prioritiesCompleted.push(3);
      }
      if (this.hasParentInCampus) {
        this.prioritiesCompleted.push(4);
      }
      if (this.hasNeep) {
        this.prioritiesCompleted.push(5);
      }
      if (this.hasSep) {
        this.prioritiesCompleted.push(6);
      }
    },
    setGradeSelected(grade, stage) {
      this.gradeSelected = { grade, stage };
    },
    setActiveStudent(index) {
      this.gradeSelected = {};
      if (this.studentsSelectInfo[index].isActive) {
        this.studentsSelectInfo[index].isActive = false;
      } else {
        this.studentsSelectInfo[index].isActive = true;
      }
      this.studentsSelectInfo.map((student) => {
        if (student.id !== index) {
          Object.assign(student, { isActive: false });
        }
        return student;
      });
      if (this.isSomeStudentActive) {
        this.setCurrentStudent({ student: this.students[index] }).then(() => {
          this.selectedSelector = 0;
          if (this.studentChange === 2) {
            this.studentChange = 3;
          } else {
            this.studentChange = 2;
          }
        });
      } else {
        this.setCurrentStudent({ student: {} }).then(() => {
          this.gradeSelected = {};
          this.selectedSelector = 0;
          this.prioritiesCompleted = [];
        });
      }
      return this.studentsSelectInfo;
    },
    getSelectionInfo() {
      if (this.isGuest || this.students.length === 0) {
        this.studentsSelectInfo.push(
          {
            label: this.$t('simulation.guest'),
            isActive: true,
            id: 0,
          },
        );
      } else {
        this.students.forEach((student, index) => {
          this.studentsSelectInfo.push(
            {
              label: student.first_name,
              isActive: index === 0,
              id: index,
            },
          );
        });
      }
    },
    setSelectedPriority(selectorId) {
      if (this.selectedSelector === selectorId) {
        this.selectedSelector = 0;
        return this.selectedSelector;
      }
      this.selectedSelector = selectorId;
      return this.selectedSelector;
    },
    setCompletedPriorities(priorityIndex) {
      this.selectorPriorityPrefill();
      this.selectedSelector = priorityIndex + 1;
    },
    confirm() {
      if (this.currentStudent && Object.keys(this.currentStudent).length > 0) {
        const gradeOptions = {
          uuid: this.currentStudent.uuid,
          grades: [this.gradeSelected.grade.id],
          stages: [this.gradeSelected.stage.id],
        };
        this.setStudentGrades({ gradeOptions, updateStudent: this.currentStudent.uuid })
          .then(() => {
            this.setSimulationModalCompleted(true);
            this.$emit('closeModal');
          });
      } else if (!this.isGuest) {
        const gradeOptions = {
          grades: [this.gradeSelected.grade.id],
          stages: [this.gradeSelected.stage.id],
        };
        this.setFastRegisterGrade({ gradesData: gradeOptions }).then(() => {
          this.setSimulationModalCompleted(true);
          this.$emit('closeModal');
        });
      } else {
        this.setGuestData({ grades: [this.gradeSelected.grade.id] }).then(() => {
          this.setIsGuestClickFirstTime({ property: 'grade' });
          this.setSimulationModalCompleted(true);
          this.$emit('closeModal');
        });
      }
    },
    goBack() {
      this.$router.push(this.simulateFromPath);
    },
  },
};
</script>
