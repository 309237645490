<template>
  <section
    class="simulation-columns right-column"
    :class="!mobile ? '' : 'right-column--left'"
  >
    <div class="simulation-columns--right">
      <span v-t="'simulation.title-simulation'" class="title-selector" />
      <p v-t="'simulation.description-simulation'" class="description-selector" />
      <v-data-table
        disable-pagination
        hide-default-footer
        hide-default-header
        :items="simulationList"
        :headers="headers"
        class="list-simulation"
      >
        <template #body="props">
          <draggable
            id="first"
            :list="simulationList"
            draggable=".item"
            :group="{ name: 'simulation', put: ['favorites', 'suggested'] }"
            ghost-class="ghost"
            @change="onDragSimulate()"
          >
            <div
              v-for="(item, index) in props.items"
              :key="item.name"
              class="item flex"
              align="left"
            >
              <span class="title-poppins primary-medium school--rank">
                {{ index + 1 }}
              </span>
              <div class="list-group-item clickable">
                <v-avatar height="72" min-width="60" tile class="image-favorites">
                  <v-img
                    class="image-favorites--border"
                    :src="
                      item.image.length > 0
                        ? item.image[0].image_link
                        : require('@/assets/icons/school-simulation.svg')
                    "
                  />
                </v-avatar>
                <span>
                  {{ item.name }}
                  <br>
                  <div>
                    {{ item.info }}
                  </div>
                </span>
                <div class="drag-icon">
                  <img
                    :src="require('@/assets/icons/drag-drop.svg')"
                    alt="drag"
                  />
                </div>
              </div>
            </div>
            <div class="flex">
              <span class="title-poppins primary-medium school--rank">
                {{ props.items.length + 1 }}
              </span>
              <div class="list-group-item--last">
                <v-avatar height="72" min-width="60" tile class="image-favorites">
                  <v-img
                    class="image-favorites--border"
                    src="@/assets/icons/image-empty.svg"
                  />
                </v-avatar>
                <div class="drag-icon">
                  <img
                    :src="require('@/assets/icons/drag-drop.svg')"
                    alt="drag"
                  />
                </div>
              </div>
            </div>
          </draggable>
        </template>
      </v-data-table>
      <v-btn rounded color="#5627FF" :disabled="simulationList.length === 0" @click="onSimulate()">
        {{ $t('simulation.simulate-button') }}
      </v-btn>
    </div>
    <v-dialog
      v-model="showSimulationResults"
      transition="slide-x-transition"
      content-class="simulation-results--dialog"
      max-width="1020"
      @click:outside="closeSimulationResults()"
    >
      <SimulationResults v-if="showSimulationResults" @closeSimulationResults="closeSimulationResults()" />
    </v-dialog>
  </section>
</template>

<script>
import SimulationResults from '@/components/explorer/simulate/SimulationResults.vue';
import '@/styles/dashboard/simulation.scss';
import draggable from 'vuedraggable';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SimulationList',
  components: {
    draggable,
    SimulationResults,
  },
  data() {
    return {
      simulationList: [],
      showSimulationResults: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      loading: 'favorites/loadingSchoolsDetails',
      favoriteSchoolsDetails: 'favorites/listFavoriteSchools',
      listFavoriteSchools: 'favorites/listFavoriteSchools',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    simulationList() {
      this.setSimulateList(this.simulationList);
    },
  },
  methods: {
    ...mapActions({
      setSimulateList: 'simulation/setSimulateList',
      cleanSimulationData: 'simulation/cleanSimulationData',
    }),
    onSimulate() {
      this.showSimulationResults = true;
    },
    closeSimulationResults() {
      this.cleanSimulationData();
      this.showSimulationResults = false;
    },
    onDragSimulate() {
      this.simulationList = this.simulationList.filter((item, index) => this.simulationList.indexOf(item) === index);
    },
  },
};
</script>
