<template>
  <!-- A squared SVG is generated. The viewbox defines a 100 x 100 coordinate space in itself -->
  <svg xmlns="http://www.w3.org/2000/svg" :width="size + 35" :height="size + 35" :viewBox="`${-(outerRadius - 20)} ${-(outerRadius - 20)} ${(outerRadius + 60) * 2} ${(outerRadius + 60) * 2}`" fill="none">
    <mask :id="`mask__${_uid}`">
      <rect x="-22%" y="-22%" width="100%" height="100%" fill="white" />
      <circle :cx="outerRadius" :cy="outerRadius" :r="innerRadius + 1" fill="black" />
    </mask>
    <g :clip-path="`url(#doughnut-layout__${_uid})`" :mask="!asDot ? `url(#mask__${_uid})` : ''">
      <circle
        id="outer-ring"
        :cx="outerRadius"
        :cy="outerRadius"
        :r="asDot ? 0 : outerRadius"
        :style="{
          fill: mainColorRGBA,
          stroke: mainColor,
          'stroke-width': (selected || exploredCard || exploredCampus) ? 22 : 8,
        }"
      />
      <circle
        v-if="selected && !asDot"
        id="outer-border"
        :cx="outerRadius"
        :cy="outerRadius"
        :r="outerRadius + 26"
        :style="{
          stroke: borderColorRGBA,
          'stroke-width': 30,
        }"
      />
      <circle
        id="inner-border"
        :cx="outerRadius"
        :cy="outerRadius"
        :r="innerRadius"
        :style="{ fill: showInnerCircle !== -1 ? innerCircleHexColor : mainColor }"
      />
      <circle
        id="inner-ring"
        :cx="outerRadius"
        :cy="outerRadius"
        :r="showInnerCircle !== -1 ? innerRingRadius : 0"
        :fill="asDot ? mainColorRGBA : innerCircleRGBA"
        :style="{
          stroke: innerCircleHexColor,
          'stroke-width': 1,
        }"
      />
    </g>
  </svg>
</template>

<script>

import { hexToRGBA, getComputedColorValue } from '@/utils/colors';

export default {
  name: 'MarkerSVG',
  props: {
    size: {
      type: Number,
      default: 50,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    exploredCampus: {
      type: Boolean,
      default: false,
    },
    exploredCard: {
      type: Boolean,
      default: false,
    },
    showInnerCircle: {
      type: Number,
      default: -1,
    },
    asDot: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      outerRadius: 80,
      innerRadius: 12,
      innerRingRadius: 35,
      defaultHexColor: getComputedColorValue('--secondary-light'),
      highlightedRGB: getComputedColorValue('--secondary-medium'),
    };
  },
  computed: {
    mainColor() {
      return this.highlighted ? this.highlightedRGB : this.defaultHexColor;
    },
    mainColorRGBA() {
      const opacity = this.exploredCampus ? 0.7 : 0.35;
      return hexToRGBA(this.mainColor, opacity);
    },
    borderColorRGBA() {
      const opacity = 0.4;
      return hexToRGBA(this.mainColor, opacity);
    },
    innerCircleRGBA() {
      return hexToRGBA(this.innerCircleHexColor, 0.8);
    },
    innerCircleHexColor() {
      const color = {
        0: getComputedColorValue('--secondary-focus'),
        1: getComputedColorValue('--primary-medium'),
      };
      return color?.[this.showInnerCircle];
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  & > g > circle {
    transition: all 0.2s ease-in-out;
}
}
</style>
