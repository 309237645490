var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"selector-buttons",class:{ 'selector-buttons--mobile': _vm.small }},[_c('button',{staticClass:"d-flex align-center",class:{
      'selector-buttons--completed': _vm.completed && !_vm.selected && !_vm.verticalButtons && !_vm.small,
      'selector-buttons--selected': _vm.selected && !_vm.verticalButtons && !_vm.small,
      'selector-buttons--pending': !_vm.completed && !_vm.selected && !_vm.verticalButtons && !_vm.small,
      'selector-buttons--vertical--button': _vm.verticalButtons,
      'selector-buttons--vertical--selected': _vm.verticalButtons && (_vm.completed || _vm.selected),
      'selector-buttons--vertical--pending': _vm.verticalButtons && (!_vm.completed && !_vm.selected),
      'selector-buttons--mobile--button': _vm.small,
      'selector-buttons--mobile--selected': _vm.small && (_vm.completed || _vm.selected),
      'selector-buttons--mobile--pending': _vm.small && (!_vm.completed && !_vm.selected),
    },attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('setValue', _vm.text)}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.text))+" "),_c('section',{staticStyle:{"margin-left":"16px"}},[(_vm.completed || _vm.selected)?_c('img',{staticStyle:{"width":"24px"},attrs:{"src":require("@/assets/svg/add-white.svg"),"alt":"Close"}}):_vm._e(),(!_vm.completed && !_vm.selected)?_c('img',{staticStyle:{"width":"24px"},attrs:{"src":require("@/assets/svg/add-primary-dark.svg"),"alt":"Add"}}):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }