<template>
  <div>
    <TextAtom
      :class="titleClass"
      :value="title"
      tag="label"
      font="poppins"
      color="primary-dark"
      weight="500"
      size="subtitle"
    />
    <ArrayButtonsContent
      :button-array-content="['arrayButtons', 'arrayButtonsText']"
      :button-array="optionsArray"
      full-width
      array-text-left-align
      click-in-clean
      class="mb-9"
      @setContentArray="setContent"
    />
  </div>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import ArrayButtonsContent from '@/components/molecules/sections/ArrayButtonsContent.vue';

export default {
  name: 'ColumnSection',
  components: {
    TextAtom,
    ArrayButtonsContent,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    titleClass() {
      return 'title-sections__location';
    },
    optionsArray() {
      return this.options;
    },
  },
  methods: {
    setContent(selectedContent) {
      this.$emit('setContentArray', selectedContent);
    },
  },
};
</script>
