<template>
  <div
    :id="mobile ? '' : 'expansionVar'"
    class="institution-info--wrapper"
    :class="{ 'institution-info--wrapper--expand': isExpanded }"
    @mouseover="expandBackground()"
    @focusin="expandBackground()"
    @mouseleave="compressBackground()"
    @focusout="compressBackground()"
  >
    <div
      class="institutions-info__header"
      :class="{
        'institutions-info__header--border': isExpanded,
        'institutions-info__header--mobile': mobile,
      }"
      height="31"
      @click="clickExpandBackground()"
      @keydown.enter="clickExpandBackground()"
    >
      <div class="d-flex justify-center align-center">
        <img
          v-if="items.main"
          :src="require(`@/assets/${items.main.src}`)"
          :alt="$t(items.main.name)"
        />
        <strong
          class="institutions-info__header__text institutions-info__header__text--strong ml-1"
        >
          {{ items.main.value }}
        </strong>
        <p
          v-if="!mobile"
          v-t="items.main.name"
          class="institutions-info__header__text ml-1"
        />
      </div>
    </div>
    <div v-if="isExpanded">
      <div
        v-for="(item, i) in items.others"
        :key="i"
        @click="selectInfoRight(item)"
        @keydown.enter="selectInfoRight(item)"
      >
        <div
          class="institution-info-slot d-flex justify-center"
          :class="{ 'institution-info-slot--mobile': mobile }"
        >
          <img
            :src="require(`@/assets/${item.src}`)"
            :alt="$t(item.name)"
          />
          <strong
            class="institutions-info__select__text institutions-info__select__text--strong ml-1"
          >
            {{ item.value }}
          </strong>
          <div
            v-if="!mobile"
            v-t="item.name"
            style="font-weight: 300"
            class="institutions-info__select__text ml-1"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SelectRightBar',
  props: {
    map: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isExpanded: false,
      mainItemIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      schools: 'institutions/schools',
      filteredExplorerSchools: 'filters/filteredExplorerSchools',
    }),
    items() {
      const allItems = [
        {
          value: this.visibleSchools,
          name: this.$tc('map.school', this.visibleSchools),
          src: 'icons/explored.svg',
          index: 0,
        },
        {
          value: this.filteredExplorerSchools?.length ?? 0,
          name: this.$tc('map.highlighted', this.filteredExplorerSchools?.length ?? 0),
          src: 'icons/highlighted.svg',
          index: 1,
        },
      ];
      const [mainItem] = allItems.splice(this.mainItemIndex, 1);
      return {
        main: mainItem,
        others: allItems,
      };
    },
    visibleSchools() {
      let count = 0;
      if (this.map) {
        this.schools.forEach((s) => {
          const { lat } = s.location;
          const lng = s.location.lon;
          if (this.map.getBounds()) {
            if (this.map.getBounds().contains({ lat, lng })) {
              count += 1;
            }
          }
        });
      }
      return count;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    expandBackground() {
      if (!this.mobile) {
        setTimeout(() => {
          this.isExpanded = true;
          return this.isExpanded;
        }, 101);
      }
    },
    compressBackground() {
      setTimeout(() => {
        this.isExpanded = false;
        return this.isExpanded;
      }, 102);
    },
    clickExpandBackground() {
      if (this.mobile) {
        if (!this.isExpanded) {
          this.isExpanded = true;
          return this.isExpanded;
        }
        this.isExpanded = false;
        return this.isExpanded;
      }
      return null;
    },
    selectInfoRight(item) {
      this.isExpanded = false;
      this.mainItemIndex = item.index;
    },
  },

};
</script>
