<template>
  <div class="d-flex gap-3 favorites-list--filters">
    <div
      v-for="(filter, index) in filtersSelected"
      :key="index"
    >
      <SelectionButton
        :text="$t(`favorite.filters_title.${filter}`)"
        is-filter
        multi-selection-icons
        @setValue="removeFilter(filter)"
      />
    </div>
  </div>
</template>

<script>
import SelectionButton from '@/components/atoms/buttons/SelectionButton.vue';
import { mapGetters, mapActions } from 'vuex';
import {
  TETHER_FILTER_CONTEXTS as FILTER_CONTEXTS,
} from '@/constants/filters';

export default {
  name: 'FiltersSelected',
  components: {
    SelectionButton,
  },
  computed: {
    ...mapGetters({
      filters: 'filters/filters',
    }),
    filtersSelected() {
      const filtersSelected = [];
      Object.keys(this.filters).forEach((key) => {
        const value = this.filters[key];
        if ((value instanceof Set && value.size > 0) || value === true) {
          filtersSelected.push(key);
        }
      });
      return filtersSelected;
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      setFilter: 'filters/setFilter',
    }),
    removeFilter(filter) {
      this.setFilter({
        context: FILTER_CONTEXTS.FAVORITES,
        key: filter,
        value: new Set(),
      });
    },
  },
};
</script>
