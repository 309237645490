<template>
  <div
    class="simulation-columns left-column"
    :class="!mobile ? 'mr-4' : 'left-column--width'"
  >
    <span v-t="'simulation.title-selector'" class="title-selector" />
    <p v-t="'simulation.description-selector'" class="description-selector" />
    <v-skeleton-loader
      v-if="loading"
      max-width="auto"
      max-height="220"
      type="list-item-avatar-two-line"
    />
    <v-data-table
      v-else
      disable-pagination
      hide-default-footer
      hide-default-header
      :items="favoritesList"
      :headers="headers"
      class="list-simulation"
    >
      <template #body="props">
        <draggable
          id="first"
          :list="favoritesList"
          draggable=".item"
          :group="{ name: 'favorites', pull: true, put: 'simulation' }"
          ghost-class="ghost"
          :sort="false"
        >
          <div
            v-for="item in props.items"
            :key="item.campus_code"
            class="list-group-item item clickable"
            align="left"
          >
            <v-avatar height="72" min-width="60" tile class="image-favorites">
              <v-img
                class="image-favorites--border"
                :src="
                  item.image.length > 0
                    ? item.image[0].image_link
                    : require('@/assets/icons/school-simulation.svg')
                "
              />
            </v-avatar>
            <span>
              {{ item.name }}
              <br>
              <div>
                {{ item.info }}
              </div>
            </span>
            <div class="drag-icon">
              <img
                :src="require('@/assets/icons/drag-drop.svg')"
                alt="drag"
              />
            </div>
          </div>
        </draggable>
        <div
          v-for="item in filterList"
          :key="item.campus_code"
          class="list-group-item item blur-item"
          align="left"
          :disabled="true"
        >
          <v-avatar height="72" min-width="60" tile class="image-favorites">
            <v-img
              class="image-favorites--border"
              :src="
                item.image.length > 0
                  ? item.image[0].image_link
                  : require('@/assets/icons/school-simulation.svg')
              "
            />
          </v-avatar>
          <span>
            {{ item.name }}
            <br>
            <div>
              {{ item.info }}
            </div>
          </span>
          <div class="blur-item--icon">
            <v-tooltip bottom content-class="tooltip-simulation">
              <template #activator="{ on, attrs }">
                <v-btn icon color="white" small v-bind="attrs" v-on="on">
                  <v-icon color="#1E0C61">
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </template>
              <span v-t="'simulation.tooltip-simulate'" />
            </v-tooltip>
          </div>
        </div>
      </template>
    </v-data-table>
    <div class="more-options">
      <span v-t="'simulation.add-schools'" class="add-schools-title" />
      <br>
      <v-btn class="left-button" rounded color="white" @click="goTo('/map')">
        <v-img
          :src="require('@/assets/icons/map-simulation.svg')"
          class="map-icon"
        />
        {{ $t('simulation.add-map') }}
      </v-btn>
      <v-btn :class="mobile ? 'mr-1.5' : 'right-button'" rounded color="white" @click="goTo('/favorites')">
        <v-img
          :src="require('@/assets/icons/list-simulation.svg')"
          class="map-icon"
        />
        {{ $t('simulation.add-list') }}
      </v-btn>
    </div>
    <div class="suggested-schools">
      <span v-t="'simulation.continue-exploring'" class="title-selector" />
      <p v-t="'simulation.continue-exploring-description'" class="description-selector" />
    </div>
    <!-- Suggested Schools -->
    <v-skeleton-loader
      v-if="!loaded"
      max-width="auto"
      max-height="220"
      type="list-item-avatar-two-line"
    />
    <v-data-table
      v-else
      disable-pagination
      hide-default-footer
      hide-default-header
      :items="suggestionsList"
      :headers="headers"
      class="list-simulation"
    >
      <template #body="props">
        <draggable
          id="first"
          :list="suggestionsList"
          draggable=".item"
          :group="{ name: 'suggested', pull: true, put: 'simulation' }"
          ghost-class="ghost"
          :sort="false"
        >
          <div
            v-for="item in props.items"
            :key="item.campus_code"
            class="list-group-item item clickable"
            align="left"
          >
            <v-avatar height="72" min-width="60" tile class="image-favorites">
              <v-img
                class="image-favorites--border"
                :src="
                  item.image.length > 0
                    ? item.image[0].image_link
                    : require('@/assets/icons/school-simulation.svg')
                "
              />
            </v-avatar>
            <span>
              {{ item.name }}
              <br>
              <div>
                {{ item.info }}
              </div>
            </span>
            <div class="drag-icon">
              <img
                :src="require('@/assets/icons/drag-drop.svg')"
                alt="drag"
              />
            </div>
          </div>
        </draggable>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CONFIG from '@/config';
import '@/styles/dashboard/simulation.scss';
import { getCampusAdmissionSystemLabel } from '@/utils/categories/admissionRisk';
import draggable from 'vuedraggable';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SimulationSelector',
  components: {
    draggable,
  },
  data() {
    return {
      favoritesList: [],
      filterList: [],
      suggestionsList: [],
      simulationList: [],
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      loaded: false,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'favorites/loadingSchoolsDetails',
      favoriteSchoolsDetails: 'favorites/listFavoriteSchools',
      favoriteGuestList: 'favorites/favoriteGuestList',
      listFavoriteSchools: 'favorites/listFavoriteSchools',
      isGuest: 'authentication/isGuest',
      activeMunicipality: 'authentication/activeMunicipality',
      activeGrades: 'authentication/activeGrades',
      simulateList: 'simulation/simulateList',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    listFavoriteSchools() {
      this.setInitialList();
    },
    activeMunicipality() {
      this.updateRecommendations();
    },
    activeGrades() {
      this.setInitialList();
      this.updateRecommendations();
    },
    simulateList() {
      this.simulationList = this.simulateList;
      this.suggestionsList = this.suggestionsList.filter(
        (x) => !this.simulationList.filter((y) => y.info === x.info).length,
      );
    },
  },
  mounted() {
    this.updateRecommendations();
    this.setInitialList();
  },
  methods: {
    ...mapActions({
      getCampusRecommendations: 'elasticSearch/getCampusRecommendations',
    }),
    goTo(page) {
      return this.$router.push(page);
    },
    updateRecommendations() {
      this.loaded = false;
      // Get the recommendations from the API, getting only public campuses (because we can't simulate private).
      // The API will return recommendations based on the user's previously saved schools, current municipality,
      // and current grades.
      this.getCampusRecommendations({
        publicOnly: true,
      }).then((recommendations) => {
        this.suggestionsList = recommendations.map((suggested) => ({
          campus_code: suggested.campus_code,
          name: suggested.campus_name,
          info: this.getInstitutionInfo(suggested),
          image: suggested.image_thumb,
          programs: suggested.programs,
          hasRisk: getCampusAdmissionSystemLabel(suggested, true).hasRisk,
        }));
        this.loaded = true;
      }).catch(() => {
        this.loaded = true;
      });
    },
    hasGrade(programs) {
      const activeGradeSet = new Set(this.activeGrades.grades);
      return programs?.some((prog) => activeGradeSet.has(prog.grade.id));
    },
    setInitialList() {
      this.favoritesList = [];
      this.filterList = [];
      const userFavorites = this.isGuest ? this.favoriteGuestList : this.favoriteSchoolsDetails;
      userFavorites.forEach((school) => {
        const { hasRisk } = getCampusAdmissionSystemLabel(school);
        const finalSchool = {
          campus_code: school.campus_code,
          name: school.campus_name,
          image: school.image_thumb,
          info: this.getInstitutionInfo(school),
        };
        if (this.hasGrade(school.programs) && hasRisk) {
          this.favoritesList.push(finalSchool);
        } else {
          this.filterList.push(finalSchool);
        }
      });
    },
    getInstitutionInfo(school) {
      const info = {
        colombia: school?.campus_name,
        chile: school?.campus_name,
        newhaven: school?.sector_label?.sector_name ?? school?.campus_name,
      };

      return info[CONFIG.tenant] ?? '';
    },
  },
};
</script>
