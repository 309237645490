<template>
  <DashboardTemplate :sections="sections" :grid-area-component="gridAreaComponentGallery">
    <template #navbarItems>
      <div class="dashboard-navbar__route">
        {{ $t("dashboard.gallery.title") }}
      </div>
    </template>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from '@/components/dashboard/Template.vue';

export default {
  name: 'GalleryView',
  components: { DashboardTemplate },
  metaInfo() {
    return {
      title: `${this.$tc('dashboard.breadcrumbs.app_name')} | ${this.$tc('dashboard.breadcrumbs.gallery')}`,
      meta: [{ name: 'robots', content: 'noindex, nofollow' }],
    };
  },
  data() {
    return {
      sections: [
        {
          name: 'image-uploader',
          title: 'Galería de imágenes',
          background: 'transparent',
          mobileHeight: 'calc(100vh - 70px)',
          showTitle: false,
          permissions: ['headmaster'],
          gridArea: 'image-uploader',
        },
        /* {
            name: 'image-tagger',
            title: 'Media',
            showTitle: false,
            permissions: ['headmaster'],
            row: '1/3',
            col: '3 / 4'
            }, */
      ],
      gridAreaComponentGallery: '"image-uploader image-uploader image-uploader image-uploader image-uploader image-uploader" "image-uploader image-uploader image-uploader image-uploader image-uploader image-uploader" "image-uploader image-uploader image-uploader image-uploader image-uploader image-uploader" "image-uploader image-uploader image-uploader image-uploader image-uploader image-uploader"',
    };
  },
};
</script>
