<template>
  <section
    :class="!mobile ? 'pt-3' : ''"
  >
    <nav class="full-width d-flex flex-row">
      <MobileExplorerHeader
        v-if="mobile"
        :show-campus-search="false"
        @toggleMenu="toggleMenu"
      />
      <v-dialog
        v-if="mobile"
        v-model="showNavigationBar"
        max-width="150"
        transition="slide-x-transition"
        content-class="main-container__row__dialog"
      >
        <CustomMenu
          :in-dashboard="true"
          @closeMenu="setMenuVisibility(false)"
        />
      </v-dialog>

      <div
        v-if="!mobile"
        class="navbar-simulate"
        :class="mobile ? 'dropdown-navbar-app' : ''"
      >
        <h3
          v-t="'simulate.title'"
          :class="mobile ? 'drop-app' : ''"
          class="navbar-simulate--title"
        />
      </div>
    </nav>
  </section>
</template>

<script>
import CustomMenu from '@/layouts/CustomMenu.vue';
import MobileExplorerHeader from '@/layouts/MobileExplorerHeader.vue';

export default {
  name: 'NavbarSimulate',
  components: {
    CustomMenu,
    MobileExplorerHeader,
  },
  data() {
    return {
      showNavigationBar: false,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    setMenuVisibility(visibility) {
      this.showNavigationBar = visibility;
    },
    toggleMenu() {
      this.showNavigationBar = !this.showNavigationBar;
    },
  },
};
</script>
