<template>
  <div class="my-2 white--text text--small-01">
    <v-icon size="17" class="school-card__main__priorities__priority"> $prioritySEP </v-icon>

    {{ $t('map.legend.priority') }}
  </div>
</template>

<script>
export default {
  name: 'MapLegend',
};
</script>
